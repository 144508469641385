<template>
  <div class="checkout">
    <Form
      ref="form"
      v-slot="{ invalid }"
      class="checkout__wrapper"
      v-if="!isPaymentExpired"
    >
      <div class="checkout__row">
        <div class="checkout__inputs">
          <button-back @click="$emit('back')" />
          <div class="checkout__form-subtitle">BILLING INFORMATION</div>
          <FormItem rules="required" customError=" ">
            <Input
              class="checkout__input"
              v-model="firstName"
              placeholder="First name*"
              :is-floating="false"
              :is-small="true"
              variant="tertiary"
            />
          </FormItem>
          <FormItem rules="required" customError=" ">
            <Input
              class="checkout__input"
              v-model="lastName"
              placeholder="Last name*"
              :is-floating="false"
              :is-small="true"
              variant="tertiary"
            />
          </FormItem>
          <FormItem rules="required|email" customError=" ">
            <Input
              class="checkout__input"
              v-model="email"
              placeholder="Email*"
              :is-floating="false"
              :is-small="true"
              variant="tertiary"
              @blur="handleBlur"
            />
          </FormItem>
          <FormItem rules="required|phone:17" customError=" ">
            <Input
              class="checkout__input"
              v-mask="'+1 (###) ###-####'"
              v-model="phone"
              placeholder="Phone*"
              :is-floating="false"
              :is-small="true"
              variant="tertiary"
            />
          </FormItem>
          <CustomFieldsInput
            v-if="customFields && Object.keys(customFields).length"
            v-model="customFields"
          />
          <template v-if="isPromocodesAvailable">
            <div class="checkout__promocode">
              <Input
                v-model="promocode"
                placeholder="Enter promocode"
                :is-floating="false"
                :is-small="true"
                variant="tertiary"
              />
              <Button
                variant="danger"
                :is-loading="isApplyingPromocode"
                is-small
                @click="handleApplyPromocode"
              >
                Apply promocode
              </Button>
            </div>
            <p
              v-if="promocodeError || promocodeSuccess"
              class="checkout__promocodeMessage"
              :class="{ 'm-green': !!promocodeSuccess }"
            >
              {{ promocodeError || promocodeSuccess }}
            </p>
          </template>
        </div>
        <div class="checkout__price">
          <price-breakdown
            class="checkout__breakdown"
            :price="price"
            :players-count="+playersCount"
            :is-group="isGroup"
          />
          <div class="checkout__note">
            <p>
              *Note: You will receive an email that confirms your booking
              details.
            </p>
            <p>
              Please check your spam inbox if you don't receive within 24 hours.
            </p>
          </div>
        </div>
      </div>

      <CardElement ref="checkoutRef" :is-completed.sync="isCardCompleted">
        <CountDown
          v-if="isShowCountDownTimer"
          @reset="handleReset"
          :expiresAt="booking?.expiresAt || booking?.abandonedAt"
        />
      </CardElement>

      <Button
        class="checkout__button"
        variant="danger"
        is-block
        :is-disabled="invalid || !selectedGroupId || !isCardCompleted"
        :is-loading="isLoading || isPriceLoading"
        @click="handleSubmit"
      >
        Pay {{ totalPrice }}
      </Button>
      <template v-if="product.upsellItems && product.upsellItems.length > 0">
        <div>
          <p class="checkout__subtitle">
            Additional items
          </p>
          <div class="checkout__upsell-items">
            <UpsellItemCard
              v-for="item in product.upsellItems"
              :key="item.id"
              :value="item.quantity"
              is-counter
              :title="item.name"
              :image="item.image"
              :description="item.description"
              :price="item.price"
              @input="handleUpsellQuantityChange(item, $event)"
            />
          </div>
        </div>
      </template>
    </Form>
    <PaymentExpired v-else @reset="$emit('reset')" />
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";
import PriceBreakdown from "@/components/badaxe/PriceBreakdown";
import dialog from "@/helpers/dialog";
import CustomFieldsInput from "@/components/widget-old/CustomFieldsInput";
import UpsellItemCard from "@/components/widget-old/UpsellItemCard";
import axios from "axios";
import { GlobalService } from "@/api/services/GlobalService";
import { BookingInteractionEnum, PromoRateTypeEnum } from "@/api/types";
import { debounce } from "debounce";
import ButtonBack from "@/components/badaxe/ButtonBack";
import CardElement from "@/components/badaxe/CardElement";
import CountDown from "@/components/CountDown";
import PaymentExpired from "@/components/PaymentExpired";

export default {
  name: "Checkout",
  components: {
    CardElement,
    ButtonBack,
    UpsellItemCard,
    CustomFieldsInput,
    PriceBreakdown,
    CountDown,
    PaymentExpired,
  },
  props: {
    isGroup: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isPaymentExpired: false,
    isDeposit: {},
    isLoading: false,
    isPriceLoading: false,
    price: null,
    upsellQuantityTimeout: null,
    isCardCompleted: false,
    promocode: "",
    appliedPromocode: "",
    isApplyingPromocode: false,
    promocodeError: "",
    promocodeSuccess: "",
  }),
  computed: {
    ...mapState({
      booking: (state) => state.booking,
      venue: (state) => state.venue,
      playersCount: (state) => state.playersCount,
      product: (state) => state.product,
      interactionClientId: (state) => state.interactionClientId,
      selectedGroupId: (state) => state.selectedGroup?.id,
      selectedGroup: (state) => state.selectedGroup,
      isPromocodesAvailable: (state) => state.isPromocodesAvailable,
      isShowCountDownTimer: (state) =>
        state?.widgetTemplate?.isShowCountDownTimer,
    }),
    ...mapGetters({
      isBadAxe: "isBadAxeWidget",
    }),
    totalPrice() {
      if (this.price) {
        if (this.price.depositPrice) {
          return `$${Math.abs(this.price.depositPrice / 100).toFixed(2)}`;
        }
        return `$${Math.abs(this.price.total / 100).toFixed(2)}`;
      }
      return "";
    },
    firstName: {
      get() {
        return this.$store.state.reservationInfo.firstName;
      },
      set(value) {
        this.$store.commit("SET_RESERVATION_INFO", {
          ...this.$store.state.reservationInfo,
          firstName: value,
        });
      },
    },
    lastName: {
      get() {
        return this.$store.state.reservationInfo.lastName;
      },
      set(value) {
        this.$store.commit("SET_RESERVATION_INFO", {
          ...this.$store.state.reservationInfo,
          lastName: value,
        });
      },
    },
    phone: {
      get() {
        return this.$store.state.reservationInfo.phone;
      },
      set(value) {
        this.$store.commit("SET_RESERVATION_INFO", {
          ...this.$store.state.reservationInfo,
          phone: value,
        });
      },
    },
    email: {
      get() {
        return this.$store.state.reservationInfo.email;
      },
      set(value) {
        this.$store.commit("SET_RESERVATION_INFO", {
          ...this.$store.state.reservationInfo,
          email: value,
        });
      },
    },
    customFields: {
      get() {
        return this.$store.state.reservationInfo.customFields;
      },
      set(value) {
        this.$store.commit("SET_RESERVATION_INFO", {
          ...this.$store.state.reservationInfo,
          customFields: value,
        });
      },
    },
    radioOptions() {
      if (!this.product?.depositType) {
        return [];
      }

      const depositValue =
        this.product.depositType === PromoRateTypeEnum.PERCENT
          ? `${this.product.deposit}%`
          : `$${this.product.deposit}`;

      return [
        {
          id: 1,
          name: "Full price at once",
          value: false,
        },
        {
          id: 2,
          name: `${depositValue} deposit `,
          value: true,
        },
      ];
    },
  },
  created() {
    this.isDeposit = this.radioOptions[0];
  },
  async mounted() {
    await this.calculatePrice();
  },
  methods: {
    ...mapActions(["addReservationInfo", "checkout", "getPrice"]),
    handleReset() {
      this.isPaymentExpired = true;
    },
    async handleSubmit() {
      this.isLoading = true;
      try {
        const cardToken = await this.$refs.checkoutRef?.submit();
        if (cardToken) {
          await this.addReservationInfo();
          await this.checkout({
            isDeposit: !!this.isDeposit?.value,
            upsellItems: this.product.upsellItems
              ?.filter((item) => item.quantity > 0)
              .map(({ id, quantity }) => ({ id, quantity })),
            paymentMethodTokenId: cardToken.id,
            promocode: this.appliedPromocode,
          });
          GlobalService.sendInteraction({
            interaction: BookingInteractionEnum.REQUEST_PAYMENT,
            clientId: this.interactionClientId,
            productId: this.product.id,
          });
          this.$emit("submit");
        }
      } catch (error) {
        await this.$modal.hide("book-modal");
        setTimeout(async () => {
          await dialog.confirm({
            title: "Something went wrong",
            message:
              error.response?.data?.raw?.message ||
              error.response?.message ||
              error.message ||
              "Please try later.",
            okText: "Ok",
          });
          window.parent.postMessage("modal-hide", "*");
        }, 0);
      } finally {
        this.isLoading = false;
      }
    },
    async calculatePrice() {
      if (this.playersCount && this.booking.slots.length) {
        let isCanceled = false;
        try {
          this.isPriceLoading = true;

          const selectedGroupId = this.selectedGroup?.id || null;

          const newPrice = await this.getPrice({
            isDeposit: !!this.isDeposit?.value,
            playersCount: this.playersCount,
            slots: this.booking.slots,
            upsellItems: this.product.upsellItems
              .filter((item) => item.quantity > 0)
              .map((item) => `${item.id}:${item.quantity}`),
            ...(selectedGroupId ? { selectedGroupId } : {}),
            promocode: this.appliedPromocode,
          });

          if (newPrice) {
            this.price = newPrice;
          }
        } catch (e) {
          isCanceled = axios.isCancel(e);
        } finally {
          if (!isCanceled) {
            this.isPriceLoading = false;
          }
        }
      }
    },
    handleUpsellQuantityChange(item, quantity) {
      item.quantity = quantity;
      if (this.upsellQuantityTimeout) {
        clearTimeout(this.upsellQuantityTimeout);
      }
      this.upsellQuantityTimeout = setTimeout(() => {
        this.calculatePrice();
        this.upsellQuantityTimeout = null;
      }, 500);
    },
    async handleBlur() {
      const validateEmail = (email) => {
        const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return regex.test(email.toUpperCase());
      };

      if (validateEmail(this.email)) {
        await this.addReservationInfo();
      }
    },
    async handleApplyPromocode() {
      try {
        this.isApplyingPromocode = true;
        this.promocodeSuccess = "";
        this.promocodeError = "";
        this.appliedPromocode = this.promocode;
        await this.calculatePrice();
        if (!this.price?.appliedPromocode?.id) {
          this.promocodeError = "Promocode is invalid";
        } else {
          this.promocodeSuccess = "Promocode is applied";
        }
      } finally {
        this.isApplyingPromocode = false;
      }
    },
  },
  watch: {
    isDeposit: debounce(async function() {
      await this.calculatePrice();
    }, 400),
  },
};
</script>
<style lang="scss" scoped>
.checkout {
  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  &__row {
    display: flex;
    flex-direction: column;
    gap: 32px;

    @media (min-width: 768px) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 24px;
    }
  }

  &__inputs {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__input {
    font-family: var(--font-family);
  }

  &__form-subtitle {
    color: var(--color-black);
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 600;
    font-family: var(--font-family);
  }

  &__price {
    display: flex;
    flex-direction: column;
  }

  &__breakdown {
    margin-bottom: 20px;
  }

  &__note {
    color: var(--color-red-10);
    font-size: 16px;
    font-weight: 600;
    font-family: var(--font-family);
    margin-top: auto;
  }

  &__calendar {
    cursor: pointer;
    border: 2px solid rgba(156, 163, 175, 1);
    border-radius: 8px;
    background-color: var(--color-white);
    padding: 14px 20px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__subtitle {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.02em;
    color: var(--color-black);
    margin-bottom: 16px;
    font-family: var(--font-family);
  }

  &__upsell-items {
    display: flex;
    flex-direction: column;
    gap: 16px;

    @media (min-width: 768px) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 16px 20px;
    }
  }

  &__deposit {
    margin-bottom: 32px;
    color: var(--color-black);
    font-family: var(--font-family);
  }

  .radio-group__label {
    margin-bottom: 6px;
  }

  .checkoutItem__error {
    top: calc(100% + 4px);
  }

  &__button {
    font-family: var(--font-family);
  }

  &__promocode {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__promocodeMessage {
    color: var(--color-danger);
    font-size: 16px;

    &.m-green {
      color: var(--color-success);
    }
  }
  &__counter {
    width: fit-content;
    margin-left: auto;
  }
}
</style>
