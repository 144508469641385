<template>
  <div :class="['priceBreakdown']">
    <div :class="['priceBreakdown__total']">
      <div class="priceBreakdown__label m-total">
        {{
          `Total${
            price ? (price.depositPrice ? " deposit" : " booking") : ""
          } price`
        }}:
      </div>
      <div class="priceBreakdown__value m-total" :class="{ 'm-red': price }">
        <span v-if="price">
          {{
            price.depositPrice
              ? getPrice(price.depositPrice)
              : price.total
              ? getPrice(price.total)
              : "-"
          }}
        </span>
        <Loader v-else size="s" color="danger" />
      </div>
    </div>
    <div class="priceBreakdown__line"></div>
    <div :class="['priceBreakdown__row']">
      <div class="priceBreakdown__label">{{ venue.name }}</div>
    </div>
    <div :class="['priceBreakdown__row']">
      <div class="priceBreakdown__label m-nowrap">
        {{ checkoutattedDate }} at {{ checkoutattedSlots }}
      </div>
    </div>
    <div :class="['priceBreakdown__row']">
      <div class="priceBreakdown__label">{{ $t("Price per person") }}:</div>
      <div class="priceBreakdown__value">
        {{ price ? getPrice(pricePerPerson) : "-" }}
      </div>
    </div>

    <template v-if="selectedGroup && price && price.depositPrice">
      <div :class="['priceBreakdown__row']">
        <div class="priceBreakdown__label">
          {{ `Deposit (${selectedGroup.value.personsToDeposit}) people` }}:
        </div>
        <div class="priceBreakdown__value">
          {{
            price
              ? getPrice(pricePerPerson * selectedGroup.value.personsToDeposit)
              : "-"
          }}
        </div>
      </div>
      <div :class="['priceBreakdown__row']">
        <div class="priceBreakdown__label">
          {{ "Total deposit (incl. tax)" }}:
        </div>
        <div class="priceBreakdown__value">
          {{ price ? getPrice(price.depositPrice) : "-" }}
        </div>
      </div>
    </template>
    <div v-else :class="['priceBreakdown__row']">
      <div class="priceBreakdown__label">{{ $t("Subtotal") }}:</div>
      <div class="priceBreakdown__value">
        {{ price ? getPrice(price.subtotal) : "-" }}
      </div>
    </div>
    <template v-if="price && price.taxes && price.taxes.length">
      <div
        class="priceBreakdown__row"
        v-for="(tax, index) in price.taxes"
        :key="`tax-${index}`"
      >
        <div class="priceBreakdown__label">{{ tax.name }}:</div>
        <div class="priceBreakdown__value">
          {{ getPrice(tax.amount * personsToDepositCoef) }}
        </div>
      </div>
    </template>
    <template v-if="price && price.upsellItems && price.upsellItems.length">
      <div
        :class="['priceBreakdown__row']"
        v-for="(item, index) in price.upsellItems"
        :key="`upsellItem-${index}`"
      >
        <div class="priceBreakdown__label">
          {{ `${item.name}${item.quantity > 1 ? ` x${item.quantity}` : ""}` }}:
        </div>
        <div class="priceBreakdown__value">
          {{ getPrice(item.amount) }}
        </div>
      </div>
    </template>
    <div
      :class="['priceBreakdown__row']"
      v-if="price && price.upsellTaxes && price.upsellTaxes.length"
    >
      <div class="priceBreakdown__label">
        {{ $t("Additional items taxes") }}:
      </div>
      <div class="priceBreakdown__value">
        {{ getPrice(additionalItemsTaxSum) }}
      </div>
    </div>
    <template v-if="price && price.discounts && price.discounts.length">
      <div
        :class="['priceBreakdown__row']"
        v-for="(item, index) in price.discounts"
        :key="`discount-${index}`"
      >
        <div class="priceBreakdown__label">{{ item.name }}:</div>
        <div class="priceBreakdown__value m-green">
          {{ getPrice(item.amount) }}
        </div>
      </div>
    </template>

    <div v-if="price && depositPrice" :class="['priceBreakdown__row']">
      <div class="priceBreakdown__label">{{ $t("To pay") }}:</div>
      <div class="priceBreakdown__value">
        {{ depositPrice || "-" }}
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import {
  getFormattedBookingTimeCheckout,
  getFormattedSlots
} from "@/helpers/utils";

export default {
  name: "PriceBreakdown",
  props: {
    price: {
      type: Object,
      default: null
    },
    playersCount: {
      type: Number,
      default: 0
    },
    isGroup: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    getPrice(price) {
      return price
        ? new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2
          }).format(price / 100)
        : "-";
    }
  },
  computed: {
    ...mapState({
      country: state => state.venue?.country,
      product: state => state.product,
      booking: state => state.booking,
      venue: state => state.venue,
      selectedGroup: state => state.selectedGroup
    }),
    taxesTotalAmount() {
      if (!this.price) return false;
      return this.price.taxes.reduce((prev, tax) => prev + tax.amount, 0);
    },
    additionalItemsTaxSum() {
      if (!this.price) return false;
      return this.price?.upsellTaxes?.reduce(
        (prev, tax) => prev + tax.amount,
        0
      );
    },
    depositPrice() {
      if (!this.price && !this.price.depositPrice) return;
      return `$${Math.abs(this.price.depositPrice / 100).toFixed(2)}`;
    },
    pricePerPerson() {
      return (
        this.price.subtotal /
        ((this.selectedGroup
          ? this.selectedGroup.value.maximum
          : this.playersCount) || 1)
      );
    },
    checkoutattedDate() {
      return getFormattedBookingTimeCheckout(this.booking);
    },
    checkoutattedSlots() {
      return getFormattedSlots(
        this.booking,
        this.venue.timezone,
        this.product.breakTime
      );
    },
    personsToDepositCoef() {
      return this.selectedGroup?.value?.personsToDeposit &&
        this.selectedGroup?.value?.maximum
        ? this.selectedGroup.value.personsToDeposit /
            this.selectedGroup.value.maximum
        : 1;
    }
  }
};
</script>
<style lang="scss" scoped>
.priceBreakdown {
  color: var(--color-black);
  font-family: var(--font-family);
  display: flex;
  flex-direction: column;
  padding-left: 0;

  @media (min-width: 768px) {
    margin-bottom: 24px;
  }

  &__row {
    &:nth-child(2n + 1) {
      background-color: var(--color-secondary-300);
    }
  }

  &__total {
    display: flex;
    justify-content: space-between;
  }

  &__line {
    width: 100%;
    height: 1px;
    background-color: #e5e7eb;
    margin: 16px 0;
  }

  &__row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
  }

  &__label {
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;

    &.m-nowrap {
      white-space: nowrap;
    }

    &.m-total {
      font-size: 20px;
      font-weight: 600;
    }
  }

  &__value {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;

    &.m-red {
      color: var(--color-red-10);
    }
    &.m-green {
      color: var(--color-success);
    }
  }
}
</style>
