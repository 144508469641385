import {
  IProduct,
  IWaiver,
  IGroup,
  IReservationInfo,
  ISignature,
  IVenue,
  IWidgetTemplate,
  IWidgetView
} from "@/helpers/interfaces";
import {
  IDates,
  ISlot,
  WidgetPositionEnum,
  WidgetThemeEnum
} from "../helpers/interfaces";

export const mutations = {
  SET_VENUE_ID(state: any, venueId: string) {
    state.venueId = venueId;
  },
  SET_WIDGET_TEMPLATE_ID(state: any, widgetTemplateId: string) {
    state.widgetTemplateId = widgetTemplateId;
  },
  SET_WIDGET_TEMPLATE(state: any, widgetTemplate: IWidgetTemplate) {
    state.widgetTemplate = widgetTemplate;
  },
  SET_WIDGET_VIEW(state: any, widgetView: IWidgetView) {
    state.widgetView = widgetView;
  },
  SET_VENUE(state: any, venue: IVenue) {
    state.venue = venue;
  },
  SET_PRODUCT_ID(state: any, productId: string) {
    state.productId = productId;
  },
  SET_WAIVER_ID(state: any, waiverId: string) {
    state.waiverId = waiverId;
  },
  SET_WAIVER_INFO(state: any, waiver: IWaiver) {
    state.waiver = waiver;
  },
  SET_IS_BASE_PRODUCT_ID(state: any, value: boolean) {
    state.isBaseProductId = value;
  },
  SET_PRODUCTS(state: any, products: IProduct[]) {
    state.products = products;
  },
  SET_IS_PRODUCTS_LOADING(state: any, value: boolean) {
    state.isProductsLoading = value;
  },
  SET_PRODUCT_INFO(state: any, product: IProduct) {
    state.product = product;
    if (state.reservationInfo && product) {
      state.reservationInfo.customFields = JSON.parse(
        JSON.stringify(product.customFields || {})
      );
    }
  },
  SET_FREE_SLOTS(state: any, slots: ISlot[]) {
    state.freeSlots = slots;
  },
  SET_ALL_SLOTS(state: any, slots: ISlot[]) {
    state.allSlots = slots;
  },
  SET_FREE_SLOTS_LOADING(state: any, isFreeSlotsLoading: boolean) {
    state.isFreeSlotsLoading = isFreeSlotsLoading;
  },
  SET_CHOSEN_DATE(state: any, date: string) {
    state.chosenDate = date;
  },
  SET_CHOSEN_SLOTS(state: any, slots: number[]) {
    state.chosenSlots = slots;
  },
  SET_BOOKING(state: any, booking: string) {
    state.booking = booking;
  },
  SET_BOOKING_ID(state: any, bookingId: string) {
    state.bookingId = bookingId;
  },
  SET_RESERVATION_INFO(state: any, reservationInfo: IReservationInfo) {
    state.reservationInfo = reservationInfo;
  },
  SET_SIGNATURE(state: any, signature: ISignature) {
    state.signature = signature;
  },
  SET_PLAYERS_COUNT(state: any, playersCount: number) {
    state.playersCount = playersCount;
  },
  SET_DATES_AVAILABILITIES(
    state: any,
    datesAvailabilities: { [key: string]: IDates }
  ) {
    state.datesAvailabilities = datesAvailabilities;
  },
  REMOVE_FROM_DATES_AVAILABILITIES(state: any) {
    state.datesAvailabilities = {};
  },
  SET_INTERACTION_CLIENT_ID(state: any, id: string) {
    state.interactionClientId = id;
  },
  SET_SELECTED_GROUP(state: any, value: IGroup) {
    state.selectedGroup = value;
  },
  SET_WIDGET_THEME(state: any, value: WidgetThemeEnum) {
    state.widgetTheme = value;
  },
  SET_IS_OPEN_INTERACTION_SENT(state: any, value: boolean) {
    state.isOpenInteractionSent = value;
  },
  SET_POSITION(state: any, value: WidgetPositionEnum) {
    state.position = value;
  },
  SET_IS_HIDE_BEACON(state: any, value: boolean) {
    state.isHideBeacon = value;
  },
  SET_IS_PROMOCODES_AVAILABLE(state: any, value: boolean) {
    state.isPromocodesAvailable = value;
  },
  SET_LOADING_CHECKOUT(state: any, value: boolean) {
    state.isLoadingCheckout = value;
  },
  SET_IS_ALWAYS_OPEN(state: any, value: boolean) {
    state.isAlwaysOpen = value;
  }
};
