var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"upsell-item-card"},[_c('div',{staticClass:"upsell-item-card__content"},[(_vm.image)?_c('div',{staticClass:"upsell-item-card__image"},[_c('img',{attrs:{"src":_vm.image,"alt":""}})]):_vm._e(),_c('div',{class:[
        'upsell-item-card__text',
        { 'm-black': _vm.isBadAxeWidget, 'm-font': _vm.isBadAxeWidget }
      ]},[_c('div',{staticClass:"upsell-item-card__top"},[_vm._v(_vm._s(_vm.title))]),_c('p',{staticClass:"upsell-item-card__description"},[_vm._v(" "+_vm._s(_vm.description || " ")+" ")])])]),(_vm.innerValue > 0)?_c('div',{class:['upsell-item-card__counter', { 'm-black': _vm.isBadAxeWidget }]},[_c('button',{class:[
        'upsell-item-card__counter__button',
        { 'm-red': _vm.isBadAxeWidget, 'm-font': _vm.isBadAxeWidget }
      ],attrs:{"type":"button"},on:{"click":function($event){_vm.innerValue = Math.max(0, _vm.innerValue - 1)}}},[_c('Icon',{attrs:{"name":"minus","size":18,"color":"white"}})],1),_vm._v(" "+_vm._s(_vm.innerValue)+" "),_c('button',{class:[
        'upsell-item-card__counter__button',
        { 'm-red': _vm.isBadAxeWidget, 'm-font': _vm.isBadAxeWidget }
      ],attrs:{"type":"button"},on:{"click":function($event){_vm.innerValue += 1}}},[_c('Icon',{attrs:{"name":"plus","size":18,"color":"white"}})],1)]):_c('button',{class:[
      'upsell-item-card__button',
      { 'upsell-item-card__button-new': _vm.isBadAxeWidget }
    ],on:{"click":function($event){_vm.innerValue = 1}}},[_vm._v(" $"+_vm._s(_vm.price)+" ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }