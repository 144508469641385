import {
  IProduct,
  IWaiver,
  IGroup,
  IWidgetTemplate,
  IWidgetView,
} from "@/helpers/interfaces";
import { IReservationInfo, ISignature, IVenue } from "@/helpers/interfaces";
import {
  IDates,
  ISlot,
  WidgetPositionEnum,
  WidgetThemeEnum,
} from "@/helpers/interfaces";

export const state = (): {
  venueId: string;
  waiverId: string;
  waiver: IWaiver | null;
  venue: IVenue | null;
  product: IProduct | null;
  products: IProduct[];
  isProductsLoading: boolean;
  productId: string;
  bookingId: string;
  playersCount: number | null;
  widgetTheme: WidgetThemeEnum;
  freeSlots: ISlot[];
  allSlots: ISlot[];
  chosenSlots: ISlot[];
  selectedGroup: IGroup | null;
  chosenDate: string;
  isFreeSlotsLoading: boolean;
  isBaseProductId: boolean;
  booking: string;
  reservationInfo: IReservationInfo;
  signature: ISignature | null;
  datesAvailabilities:
    | {
        [key: string]: IDates;
      }
    | {};
  interactionClientId: string | null;
  isOpenInteractionSent: boolean;
  position: WidgetPositionEnum;
  isHideBeacon?: boolean;
  isPromocodesAvailable: boolean;
  isShowCountDownTimer: boolean;
  isLoadingCheckout: boolean;
  widgetTemplateId?: string;
  widgetTemplate?: IWidgetTemplate;
  widgetView?: IWidgetView;
  isAlwaysOpen: boolean;
} => ({
  venueId: "",
  waiverId: "",
  venue: null,
  product: null,
  waiver: null,
  products: [],
  widgetTheme: WidgetThemeEnum.YOREFEREE,
  isProductsLoading: false,
  productId: "",
  bookingId: "",
  playersCount: null,
  selectedGroup: null,
  freeSlots: [],
  allSlots: [],
  isFreeSlotsLoading: false,
  isBaseProductId: false,
  chosenSlots: [],
  chosenDate: "",
  booking: "",
  reservationInfo: {
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    comment: "",
    customFields: {},
  },
  signature: null,
  datesAvailabilities: {},
  interactionClientId: null,
  isOpenInteractionSent: false,
  position: WidgetPositionEnum.FLOATING,
  isHideBeacon: false,
  isPromocodesAvailable: false,
  isLoadingCheckout: false,
  isAlwaysOpen: false,
  isShowCountDownTimer: false,
});
