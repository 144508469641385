<template>
  <div class="cardElement m-small">
    <div class="cardElement__header">
      <p class="cardElement__label">Enter your card details:</p>
      <slot></slot>
    </div>
    <StripeElementCard
      ref="cardElement"
      :pk="stripePublishableKey"
      :stripe-account="venue.stripeAccount"
      @element-change="handleChange"
      @token="handleToken"
      @error="handleError"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import { StripeElementCard } from "@vue-stripe/vue-stripe";

export default {
  name: "CardElement",
  components: { StripeElementCard },
  props: {
    isCompleted: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      stripePublishableKey: process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY,
      resolveToken: null,
      rejectToken: null,
    };
  },
  computed: {
    ...mapState({
      venue: (state) => state.venue,
    }),
  },
  methods: {
    handleChange(data) {
      this.$emit("update:isCompleted", !!data?.complete);
    },
    async handleToken(token) {
      if (this.resolveToken) {
        this.resolveToken(token);
        this.resolveToken = null;
      }
    },
    handleError(err) {
      if (this.rejectToken) {
        this.rejectToken(err);
        this.rejectToken = null;
      }
    },
    submit() {
      return new Promise((resolve, reject) => {
        this.$refs.cardElement?.submit();
        this.resolveToken = resolve;
        this.rejectToken = reject;
      });
    },
  },
};
</script>

<style lang="scss">
.bookingCheckout {
  &__title {
    color: var(--color-white);
  }
}

#stripe-element-errors {
  color: var(--color-danger);
  margin-top: 12px;
}

.cardElement {
  display: grid;
  gap: 16px;

  &__label {
    font-size: 12px;
    font-weight: 500;
    line-height: 12px;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
</style>
