export interface ResponseSuccessHandler {
  (data: any): void;
}

export interface ResponseErrorHandler {
  (message: string): void;
}

export interface RegistrationCredentials {
  email: string;
  name: string;
  lastName: string;
  phone: string;
  gender: string;
  unEU: boolean;
  password: string;
  passwordConfirmation: string;
}

export enum BookingInteractionEnum {
  OPEN,
  SELECT_PRODUCT,
  PLAYERS_COUNT,
  SELECT_SLOTS,
  REQUEST_PAYMENT,
  PAYMENT_SUCCESS
}

export interface IBookingInteractionPayload {
  productId?: string;
  interaction: BookingInteractionEnum;
  clientId: string;
}

export enum PromoRateTypeEnum {
  FIXED = "fixed",
  PERCENT = "percent"
}
