<template>
  <div class="steps">
    <div
      class="steps__item"
      :class="{ 'm-active': item.id === active }"
      v-for="(item, index) in list"
      :key="item.id"
    >
      {{ index + 1 }}. {{ item.title }}
    </div>
  </div>
</template>

<script>
export default {
  name: "Steps",
  props: {
    list: {
      type: Array,
      required: true
    },
    active: {
      type: Number,
      default: 0
    }
  }
};
</script>

<style lang="scss" scoped>
.steps {
  display: flex;
  flex-direction: row;
  gap: 4px;
  width: 100%;
  margin-bottom: 24px;
  padding-top: 36px;

  @media (min-width: 768px) {
    padding-top: 48px;
  }

  &__item {
    color: var(--color-white);
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
    background-color: var(--color-gray-75);
    padding: 8px 0;
    width: 100%;
    text-align: center;
    position: relative;
    transition: 0.3s;

    @media (min-width: 768px) {
      font-size: 16px;
    }

    &.m-active {
      background-color: var(--color-red-10);

      &:before {
        opacity: 1;
      }
    }

    &:first-of-type {
      border-top-left-radius: 6px;
    }

    &:last-of-type {
      border-top-right-radius: 6px;
    }

    &:before {
      opacity: 0;
      content: "";
      position: absolute;
      top: -36px;
      left: 50%;
      transform: translate(-50%, 0);
      width: 24px;
      height: 24px;
      background-repeat: no-repeat;
      background-position: bottom;
      background-size: cover;
      background-image: url('data:image/svg+xml;charset=utf8,%3Csvg xmlns="http://www.w3.org/2000/svg" width="19.249" height="20.388" viewBox="0 0 19.249 20.388"%3E %3Cpath id="Icon_open-arrow-thick-bottom" data-name="Icon open-arrow-thick-bottom" d="M6.416,7.4V18.166H0l9.721,9.624,9.528-9.624H12.833V7.4Z" transform="translate(0 -7.403)" fill="%23e82626"/%3E%3C/svg%3E');
      animation: bounce 1s infinite;
      transition: opacity 0.3s, transform 0.3s;

      @media (min-width: 768px) {
        top: -48px;
        width: 32px;
        height: 32px;
      }
    }
  }
}

@keyframes bounce {
  0%,
  to {
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
    transform: translate(-50%, -25%);
  }

  50% {
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
    transform: translate(-50%, 0);
  }
}
</style>
