var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.chosenDate)?_c('div',{staticClass:"time-slots"},[_c('div',{staticClass:"time-slots__info"},[_c('div',{staticClass:"time-slots__subtitle"},[_vm._v("Selected Date")]),_c('div',{staticClass:"time-slots__line"}),_c('div',{staticClass:"time-slots__weekday"},[_vm._v(_vm._s(_vm.weekday))]),_c('div',{staticClass:"time-slots__date"},[_vm._v(" "+_vm._s(_vm.fullMonth)+" "+_vm._s(_vm.selectedDate.date)+" ")]),_c('button',{staticClass:"time-slots__back",on:{"click":function($event){return _vm.$emit('back')}}},[_c('Icon',{attrs:{"name":"arrow-left","color":"black"}}),_vm._v(" Change Date ")],1)]),_c('div',{staticClass:"time-slots__time"},[_c('div',{staticClass:"time-slots__subtitle"},[_vm._v(" Select from Available time slots: ")]),(_vm.showTimeSlots && !_vm.isDatesLoading && !_vm.isBookingCreating)?_c('time-slot',{staticClass:"time-slots__time-slots",attrs:{"free-slots":_vm.freeSlots,"all-slots":_vm.getArrayWithUTCOffset(_vm.allSlots),"selected-slots":_vm.getArrayWithUTCOffset(_vm.chosenSlots) || [],"variant":"secondary","isSquare":false,"isPublic":false,"players-count":_vm.playersCount,"hours-format":_vm.hoursFormat},on:{"select":_vm.handleSlotsSelect}}):_c('div',{staticClass:"time-slots__loader"},[(_vm.isLoading || _vm.isDatesLoading || _vm.isBookingCreating)?_c('Loader',{attrs:{"color":"red","size":"l"}}):_c('div',{staticClass:"time-slots__emptyText"},[_vm._v(" "+_vm._s(_vm.$t("There is no slots for this date"))+" ")])],1),(_vm.product.terms)?_c('div',{class:[
          'time-slots__terms',
          { 'time-slots__terms--active': !_vm.isAcceptTerms }
        ]},[_c('Checkbox',{staticClass:"time-slots__terms-checkbox",attrs:{"is-disabled":_vm.isLoading || _vm.isDatesLoading || _vm.isBookingCreating,"variant":"tertiary"},model:{value:(_vm.isAcceptTerms),callback:function ($$v) {_vm.isAcceptTerms=$$v},expression:"isAcceptTerms"}}),_c('span',{staticClass:"time-slots__terms-text"},[_c('a',{staticClass:"time-slots__terms-link",on:{"click":function($event){$event.stopPropagation();return _vm.openTerms.apply(null, arguments)}}},[_vm._v(" Agree to Cancellation and Minimum Booking Policy? ")])])],1):_vm._e(),(_vm.isLargeGroupDisclaimer)?_c('div',{class:[
          'time-slots__terms',
          { 'time-slots__terms--active': !_vm.isAcceptLargeGroupDisclaimer }
        ]},[_c('Checkbox',{staticClass:"time-slots__terms-checkbox",attrs:{"is-disabled":_vm.isLoading || _vm.isDatesLoading || _vm.isBookingCreating,"variant":"tertiary"},model:{value:(_vm.isAcceptLargeGroupDisclaimer),callback:function ($$v) {_vm.isAcceptLargeGroupDisclaimer=$$v},expression:"isAcceptLargeGroupDisclaimer"}}),_c('span',{staticClass:"time-slots__terms-text"},[_c('a',{staticClass:"time-slots__terms-link",on:{"click":function($event){$event.stopPropagation();return _vm.openDisclaimer(_vm.largeGroupDisclaimerModalName)}}},[_vm._v(" Large Group Disclaimer ")])])],1):_vm._e(),(_vm.isHolidayDisclaimer)?_c('div',{class:[
          'time-slots__terms',
          { 'time-slots__terms--active': !_vm.isAcceptHolidayDisclaimer }
        ]},[_c('Checkbox',{staticClass:"time-slots__terms-checkbox",attrs:{"is-disabled":_vm.isLoading || _vm.isDatesLoading || _vm.isBookingCreating,"variant":"tertiary"},model:{value:(_vm.isAcceptHolidayDisclaimer),callback:function ($$v) {_vm.isAcceptHolidayDisclaimer=$$v},expression:"isAcceptHolidayDisclaimer"}}),_c('span',{staticClass:"time-slots__terms-text"},[_c('a',{staticClass:"time-slots__terms-link",on:{"click":function($event){$event.stopPropagation();return _vm.openDisclaimer(_vm.holidayDisclaimerModalName)}}},[_vm._v(" Holiday Disclaimer ")])])],1):_vm._e()],1),_c('div',{staticClass:"time-slots__policy"},[_c('p',[_vm._v(_vm._s(_vm.product && _vm.product.widgetNote ? _vm.product.widgetNote : ""))]),_c('p',[_c('span',{staticClass:"m-red m-bold"},[_vm._v("Cancellation Policy:")]),_vm._v(" "+_vm._s(_vm.cancellationText)+" ")])]),(_vm.product.terms)?_c('TermsModal',{attrs:{"name":_vm.termsModalName,"text":_vm.sanitize(_vm.product.terms.text)},on:{"click":function($event){_vm.isAcceptTerms = true}}}):_vm._e(),(_vm.isLargeGroupDisclaimer)?_c('DisclaimerModal',{attrs:{"name":_vm.largeGroupDisclaimerModalName,"title":"LARGE GROUPS","text":_vm.sanitize(_vm.product.largeGroupDisclaimer.text)},on:{"click":function($event){_vm.isAcceptLargeGroupDisclaimer = true}}}):_vm._e(),(_vm.isHolidayDisclaimer)?_c('DisclaimerModal',{attrs:{"name":_vm.holidayDisclaimerModalName,"title":"HOLIDAY","text":_vm.sanitize(_vm.product.holidayDisclaimer.text)},on:{"click":function($event){_vm.isAcceptHolidayDisclaimer = true}}}):_vm._e()],1):_vm._e(),_c('div',{staticClass:"time-slots__button-wrapper"},[_c('Button',{staticClass:"time-slots__button",attrs:{"variant":"danger","is-disabled":_vm.isLoading ||
          _vm.isDatesLoading ||
          _vm.isBookingCreating ||
          !_vm.isChosenSlots ||
          (_vm.product.terms && !_vm.isAcceptTerms) ||
          (_vm.isLargeGroupDisclaimer && !_vm.isAcceptLargeGroupDisclaimer) ||
          (_vm.isHolidayDisclaimer && !_vm.isAcceptHolidayDisclaimer)},on:{"click":_vm.handleSubmit}},[_vm._v(" Next ")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }