<template>
  <div>
    <div
      class="custom-fields-input mb-24"
      :class="{
        'm-grid': isGrid,
        'm-margin': isBadAxeWidget
      }"
    >
      <template
        v-for="field in sortedFields.filter(
          field => !isFieldMultiple(field) && !isFieldFlag(field)
        )"
      >
        <FormItem
          :key="`${field.key}_1`"
          :rules="getFieldRules(field)"
          v-slot="{ isError }"
        >
          <Select
            v-if="isFieldSelect(field)"
            :value="
              customSelectOptionMap[field.key]
                ? CUSTOM_SELECT_OPTION
                : field.value
            "
            :options="getFieldOptions(field)"
            :placeholder="field.label + required(field)"
            :is-error="isError"
            :is-small="isBadAxeWidget || isBadAxe"
            :is-floating="!(isBadAxeWidget || isBadAxe)"
            :variant="isBadAxeWidget || isBadAxe ? 'secondary' : 'primary'"
            @input="handleSelect(field.key, $event)"
          />
          <Input
            v-else-if="!isFieldDate(field)"
            :value="field.value"
            :placeholder="field.label + required(field)"
            v-mask="getFieldMask(field)"
            :hint="field.hint"
            :is-error="isError"
            :is-floating="!(isBadAxeWidget || isBadAxe)"
            :is-small="isBadAxeWidget || isBadAxe"
            :variant="isBadAxeWidget || isBadAxe ? 'tertiary' : 'primary'"
            @input="handleInput(field.key, $event)"
          />
          <Input
            v-else
            :value="field.value"
            :max="field.isRestrictFutureDates ? currentDate : ''"
            :isError="isError"
            :isDate="isFieldDate(field)"
            :hint="field.hint"
            :placeholder="field.label + required(field)"
            :dateFormat="venue.dateFormat"
            :isTextInput="isFieldDate(field)"
            :is-floating="!(isBadAxeWidget || isBadAxe)"
            :is-small="isBadAxeWidget || isBadAxe"
            :variant="isBadAxeWidget || isBadAxe ? 'tertiary' : 'primary'"
            @input="handleInput(field.key, $event)"
          />
        </FormItem>
        <FormItem
          v-if="
            isFieldSelect(field) &&
              field.isAllowCustomOption &&
              customSelectOptionMap[field.key]
          "
          :key="`${field.key}_1`"
          v-slot="{ isError }"
        >
          <Input
            :value="field.value"
            :placeholder="`${field.label + required(field)} (Your variant)`"
            :hint="field.hint"
            :is-error="isError"
            :is-floating="!(isBadAxeWidget || isBadAxe)"
            :is-small="isBadAxeWidget || isBadAxe"
            :variant="isBadAxeWidget || isBadAxe ? 'tertiary' : 'primary'"
            @input="handleInput(field.key, $event)"
          />
        </FormItem>
      </template>
    </div>
    <FormItem
      class="mb-24"
      v-for="field in sortedFields.filter(isFieldMultiple)"
      :key="field.key"
      :rules="getFieldRules(field)"
    >
      <CheckboxGroup
        :value="field.value"
        :variant="isBadAxe ? 'tertiary' : 'secondary'"
        :options="getFieldOptions(field)"
        :label="field.label + required(field)"
        @input="handleInput(field.key, $event)"
      />
    </FormItem>
    <FormItem
      class="mb-24"
      v-for="field in sortedFields.filter(isFieldFlag)"
      :key="field.key"
      :rules="getFieldRules(field)"
    >
      <Checkbox
        :value="field.value"
        :variant="isBadAxe ? 'tertiary' : 'secondary'"
        @input="handleInput(field.key, $event)"
      >
        {{ field.label + required(field) }}
      </Checkbox>
    </FormItem>
  </div>
</template>

<script>
import { CustomFieldTypeEnum } from "@/helpers/interfaces";
import { mapState, mapGetters } from "vuex";

export default {
  name: "CustomFieldsInput",
  props: {
    value: {
      type: Object,
      default: () => ({})
    },
    isGrid: {
      type: Boolean,
      default: false
    },
    isBadAxe: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      CUSTOM_SELECT_OPTION: "CUSTOM_SELECT_OPTION",
      customSelectOptionMap: {}
    };
  },
  computed: {
    ...mapState({
      venue: state => state.venue
    }),
    ...mapGetters({
      isBadAxeWidget: "isBadAxeWidget"
    }),
    fields: {
      get() {
        return { ...this.value };
      },
      set(newValue) {
        this.$emit("input", newValue);
      }
    },
    sortedFields() {
      return Object.entries(this.fields)
        .filter(([, value]) => !value.isDisabled)
        .map(([key, value]) => ({
          key,
          ...value
        }))
        .sort((a, b) => a.sortWeight - b.sortWeight);
    },
    currentDate() {
      const offset = new Date().getTimezoneOffset();
      const formattedDate = new Date(new Date().getTime() - offset * 60 * 1000);
      return formattedDate.toISOString().split("T")[0];
    }
  },
  methods: {
    getFieldRules(field) {
      const rules = {};
      if (field.isRequired) {
        if (this.isFieldFlag(field)) {
          rules["required"] = { allowFalse: false };
        } else {
          rules["required"] = true;
        }
      }
      if (field.type === CustomFieldTypeEnum.EMAIL) {
        rules["email"] = true;
      } else if (field.type === CustomFieldTypeEnum.PHONE) {
        rules["phone"] = 17;
      } else if (field.type === CustomFieldTypeEnum.NUMERIC) {
        rules["numeric"] = true;
      }

      return rules;
    },
    getFieldMask(field) {
      if (field.type === CustomFieldTypeEnum.PHONE) {
        return "+1 (###) ###-####";
      }
    },
    isFieldDate(field) {
      return field?.type === CustomFieldTypeEnum.DATE;
    },
    isFieldSelect(field) {
      return field.type === CustomFieldTypeEnum.SELECT;
    },
    isFieldMultiple(field) {
      return field.type === CustomFieldTypeEnum.MULTIPLE;
    },
    isFieldFlag(field) {
      return field.type === CustomFieldTypeEnum.FLAG;
    },
    getFieldOptions(field) {
      return [
        ...(field.options?.map(option => ({
          value: option,
          name: option
        })) || []),
        field.isAllowCustomOption && {
          value: this.CUSTOM_SELECT_OPTION,
          name: "Other"
        }
      ].filter(Boolean);
    },
    handleInput(fieldKey, value) {
      const copy = { ...this.fields };

      copy[fieldKey].value = value;
      this.fields = copy;
    },
    required(field) {
      return field.isRequired ? "*" : "";
    },
    handleSelect(fieldKey, value) {
      if (
        this.fields[fieldKey]?.isAllowCustomOption &&
        value === this.CUSTOM_SELECT_OPTION
      ) {
        this.customSelectOptionMap[fieldKey] = true;
        this.handleInput(fieldKey, "");
      } else {
        delete this.customSelectOptionMap[fieldKey];
        this.handleInput(fieldKey, value);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.custom-fields-input {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 24px;

  &.m-grid {
    @media (min-width: 1024px) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 24px 20px;
      margin-bottom: 24px;
    }
  }

  &.m-margin {
    margin: 0;
    gap: 8px;
  }
}
</style>
