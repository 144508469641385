import Vue from "vue";

const state = Vue.observable({
  type: "alert",
  active: false,
  message: "",
  title: "",
  okText: "",
  cancelText: "",
  isHtml: false
});

interface IOptions {
  title: string;
  okText: string;
  message?: string;
  cancelText?: string;
  isHtml?: boolean;
}

let close: (arg: boolean) => void;
const dialogPromise = () =>
  new Promise(resolve => (close = resolve as (arg: boolean) => void));

const open = ({ title, message, okText, cancelText, isHtml }: IOptions) => {
  state.message = message || "";
  state.title = title;
  state.okText = okText;
  state.cancelText = cancelText || "";
  state.isHtml = isHtml || false;
  state.active = true;
  return dialogPromise();
};

const reset = () => {
  state.active = false;
  state.message = "";
  state.title = "";
  state.okText = "";
  state.cancelText = "";
};

const dialog = {
  get state() {
    return state;
  },

  alert(options: IOptions) {
    state.type = "alert";
    return open(options);
  },
  confirm(options: IOptions) {
    state.type = "confirm";
    return open(options);
  },

  ok() {
    close(true);
    reset();
  },

  cancel() {
    close(false);
    reset();
  }
};

export default dialog;
