<template>
  <button class="button-back" @click="$emit('click')">
    <Icon name="arrow-left" color="black" />
    Back
  </button>
</template>
<script>
export default {
  name: "ButtonBack"
};
</script>
<style lang="scss" scoped>
.button-back {
  color: var(--color-black);
  font-family: var(--font-family);
  font-size: 20px;
  display: flex;
  gap: 5px;
  border: none;
  background-color: transparent;
  padding: 0;
  cursor: pointer;
  margin-bottom: 24px;

  @media (min-width: 768px) {
    margin: 24px 0;
  }
}
</style>
