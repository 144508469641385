<template>
  <div class="smallGroupAlert">
    <p class="smallGroupAlert__text">
      <span class="m-bold">{{ TEXT.bold }}</span>
      {{ TEXT.normal }}
    </p>
    <p class="smallGroupAlert__subtitle">{{ SUBTITLE }}</p>
    <div class="smallGroupAlert__button-group">
      <button @click="onView" class="smallGroupAlert__button">
        View Walk-in Schedule
      </button>
      <button class="smallGroupAlert__button" @click="$emit('increase')">
        Increase My Group Size
      </button>
    </div>
  </div>
</template>

<script>
import { TEXT, SUBTITLE } from "@/mocks/smallGroup";

export default {
  name: "SmallGroupAlert",
  props: {
    hash: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      TEXT,
      SUBTITLE
    };
  },
  methods: {
    onView() {
      window.location.hash = this.hash;
    }
  }
};
</script>

<style lang="scss" scoped>
.smallGroupAlert {
  padding: 16px;
  border: 1px solid var(--color-red-10);
  max-width: 460px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 16px;

  &__text {
    padding: 16px;
    margin-bottom: 16px;
    font-size: 16px;
    line-height: 24px;
    background-color: var(--color-red-10);
    border-radius: 8px;
    color: var(--color-white);

    .m-bold {
      font-weight: 600;
    }
  }

  &__subtitle {
    font-size: 16px;
    color: var(--color-black);
    margin-bottom: 16px;
    text-align: center;
  }

  &__button-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
  }

  &__button {
    padding: 12px 16px;
    border: 2px solid var(--color-black);
    font-size: 16px;
    color: var(--color-black);
    border-radius: 26px;
    background-color: transparent;
    cursor: pointer;
    white-space: nowrap;
    height: 48px;
    text-decoration: none;
    display: block;
    transition: color 0.3s, background-color 0.3s;

    &:hover {
      background-color: var(--color-red-10);
      color: var(--color-white);
    }
  }
}
</style>
