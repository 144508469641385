<template>
  <Form class="datepicker" v-if="venue && product">
    <div
      :class="['datepicker__groups-wrapper', { isNotSelect: !selectedGroup }]"
      v-if="groupsOptions"
    >
      <div class="datepicker__subtitle">Select from Available group sizes:</div>
      <div class="datepicker__buttons-group-wrapper">
        <buttons-group
          class="datepicker__groups"
          v-model="SelectedGroup"
          variant="red"
          :options="groupsOptions"
        />
      </div>
    </div>
    <Loader
      v-else
      size="m"
      :color="isBadAxe ? 'danger' : 'primary'"
      class="mx-auto"
    />
    <div
      v-if="!isShowAlert"
      :class="[
        'datepicker__calendar-wrapper',
        { isNotSelect: !isDatesLoading && selectedGroup && !chosenDate }
      ]"
    >
      <CalendarDouble
        class="datepicker__calendar"
        ref="calendar"
        :date="selectedDate"
        :dates="dates"
        :firstDayOfWeek="6"
        :is-dates-before-today-blocked="true"
        :is-all-dates-blocked="!SelectedGroup || isDatesLoading"
        variant="quaternary"
        @selectDate="handleDateSelect"
        @change="handleMonthChange"
      />
      <div class="datepicker__helper">
        <span class="m-gray">No Availability</span>
        <span class="m-blue">Available Dates</span>
        <span class="m-red">Selected Date</span>
      </div>
      <div v-if="isDatesLoading" class="datepicker__calendar-overlay">
        <Loader color="danger" size="m" />
      </div>
    </div>
    <SmallGroupAlert v-else hash="walkins" @increase="onGroupChange" />
    <div class="datepicker__button-wrapper">
      <Button
        class="datepicker__button"
        variant="danger"
        :is-disabled="!chosenDate"
        @click="handleSubmit"
      >
        Next
      </Button>
    </div>
    <!--    <WalkInBanner v-if="isTodaySelected" />-->
  </Form>
</template>
<script>
import { Components } from "@zteam/booking-axe-sb";
const { CalendarDouble } = Components;
import { debounce } from "debounce";
import moment from "moment";
import { mapGetters, mapActions, mapState, mapMutations } from "vuex";
import { BookingInteractionEnum } from "@/api/types";
import { GlobalService } from "@/api/services/GlobalService";
import SmallGroupAlert from "@/components/badaxe/SmallGroupAlert";
// import WalkInBanner from "@/components/badaxe/WalkInBanner";

export default {
  name: "Datepicker",
  components: {
    // WalkInBanner,
    CalendarDouble,
    SmallGroupAlert
  },
  data: () => ({
    isBookingCreating: false,
    isDatesLoading: false,
    dates: [],
    isPlayersCountInteractionSent: false,
    currentMonths: []
  }),
  computed: {
    ...mapState({
      playersCount: state => state.playersCount,
      chosenDate: state => state.chosenDate,
      product: state => state.product,
      productId: state => state.productId,
      booking: state => state.booking,
      venue: state => state.venue,
      datesAvailabilities: state => state.datesAvailabilities,
      interactionClientId: state => state.interactionClientId,
      selectedGroup: state => state.selectedGroup
    }),
    ...mapGetters({
      isBadAxe: "isBadAxeWidget"
    }),
    SelectedGroup: {
      get() {
        return this.selectedGroup;
      },
      set(value) {
        if (this.$store.state.isOpenInteractionSent) {
          this.sendPlayersCountInteraction();
        }
        this.setSelectedGroup(value);
      }
    },
    PlayersCount: {
      get() {
        return this.playersCount;
      },
      set(value) {
        this.setPlayersCount(value);
      }
    },
    groupsOptions() {
      return this.product?.groups
        .map(group => {
          if (
            group.maximum > +this.product?.maxPlayers ||
            group.minimum < +this.product?.minPlayers
          )
            return;
          return {
            id: group.uuid,
            value: {
              minimum: group.minimum,
              maximum: group.maximum,
              pricePerPerson: group.pricePerPerson,
              personsToDeposit: group.personsToDeposit,
              isWalkInOnly: group.isWalkInOnly,
              isLargeGroup: group.isLargeGroup
            },
            label: `${group.minimum}-${group.maximum}`
          };
        })
        .filter(item => item !== undefined);
    },
    isStaticPlayersCount() {
      if (!this.product) return;
      return this.product.maxPlayers === this.product.minPlayers;
    },
    selectedDate() {
      if (!this.chosenDate) return [];

      const date = new Date(moment(this.chosenDate, "YYYY-MM-DD"));

      return {
        date: date.getDate(),
        month: date.getMonth(),
        year: date.getFullYear()
      };
    },
    isShowAlert() {
      return this.SelectedGroup === null
        ? false
        : this.SelectedGroup.value.isWalkInOnly;
    },
    isTodaySelected() {
      return moment().isSame(moment(this.chosenDate), "date");
    }
  },
  async created() {
    this.SelectedGroup = null;

    this.setChosenSlots([]);
    this.setChosenDate("");

    this.PlayersCount = this.selectedGroup?.value.maximum || 1;
  },
  methods: {
    ...mapActions({
      getDates: "getDates"
    }),
    ...mapMutations({
      setChosenSlots: "SET_CHOSEN_SLOTS",
      setSelectedGroup: "SET_SELECTED_GROUP",
      setPlayersCount: "SET_PLAYERS_COUNT",
      setChosenDate: "SET_CHOSEN_DATE",
      setDatesAvailabilities: "SET_DATES_AVAILABILITIES",
      removeFromDatesAvailabilities: "REMOVE_FROM_DATES_AVAILABILITIES"
    }),
    handleMonthChange(months) {
      this.currentMonths = months;
      this.fetchAvailabilitiesDates(months);
    },
    async fetchAvailabilitiesDates(months) {
      if (!this.SelectedGroup?.value || this.SelectedGroup.value.isWalkInOnly) {
        return;
      }
      try {
        this.isDatesLoading = true;
        this.setChosenDate("");
        if (this.productId) {
          const dates = await Promise.all(
            months.map(month =>
              this.getDates({
                playersCount: this.playersCount,
                month
              })
            )
          );

          const datesObj = months.map((month, index) => {
            return {
              [month]: dates[index]
            };
          });

          this.setDatesAvailabilities({
            ...this.datesAvailabilities,
            ...datesObj
          });

          this.dates = dates || null;

          Object.keys(this.dates).forEach(date => {
            const item = this.dates[date];
            for (const key in item) {
              this.dates[date][key] = {
                ...this.dates[date][key],
                fromPrice: this.selectedGroup?.value.fixedPrice * 100,
                isDeposit: !!this.product?.depositType
              };
            }
          });
        }
      } catch (e) {
        console.log(e);
      } finally {
        this.isDatesLoading = false;
      }
    },
    async handleDateSelect(date) {
      const chosenDate = moment(
        new Date(date.year, date.month, date.date).toDateString()
      ).format("YYYY-MM-DD");
      this.setChosenDate(chosenDate);

      // if (this.selectedGroup?.id && this.chosenDate) {
      //   this.handleSubmit();
      // }
    },
    handleSubmit() {
      if (this.selectedGroup?.id && this.chosenDate) {
        this.sendPlayersCountInteraction();
        this.$emit("submit");
      }
    },
    sendPlayersCountInteraction() {
      if (!this.isPlayersCountInteractionSent) {
        try {
          this.isPlayersCountInteractionSent = true;
          GlobalService.sendInteraction({
            interaction: BookingInteractionEnum.PLAYERS_COUNT,
            clientId: this.interactionClientId,
            productId: this.product?.id
          });
        } catch {
          this.isPlayersCountInteractionSent = false;
        }
      }
    },
    onGroupChange() {
      this.SelectedGroup = this.groupsOptions.find(
        item => !item.value.isWalkInOnly
      );
    }
  },
  watch: {
    isStaticPlayersCount(val) {
      if (val) {
        this.PlayersCount = this.product.maxPlayers;
      }
    },
    playersCount: debounce(async function() {
      if (
        +this.playersCount > +this.product.maxPlayers ||
        +this.playersCount < (+this.product?.minPlayers || 1)
      )
        return;
      this.removeFromDatesAvailabilities();
      await this.fetchAvailabilitiesDates(this.currentMonths);
    }, 400),
    selectedGroup(val) {
      this.setPlayersCount(val?.value?.maximum || 1);
    }
  }
};
</script>
<style lang="scss" scoped>
@keyframes bounceBorder {
  from {
    border-width: 4px;
    border-style: solid;
    border-color: #dd5252;
    box-shadow: 0 1px 10px #dd5252;
  }
  to {
    box-shadow: 0 1px 5px #dd5252;
    border-color: transparent;
  }
}

.datepicker {
  display: flex;
  flex-direction: column;

  &__buttons-group-wrapper {
    .buttons-group {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;

      @media (min-width: 480px) {
        max-width: 320px;
        margin: 0 auto;
      }

      @media (min-width: 575px) {
        max-width: initial;
        margin: 0;
        flex-direction: row;
        flex-wrap: nowrap;
      }
    }

    .button-select {
      width: 120px;
    }
  }

  &__groups-wrapper {
    border: 4px solid transparent;
    border-radius: 5px;
    margin-bottom: 40px;
    padding: 10px 5px;
    display: flex;
    flex-direction: column;
    align-items: center;

    &.isNotSelect {
      animation: bounceBorder 1s ease-in-out infinite alternate;
    }
  }

  &__groups {
    // margin-bottom: 40px;
  }

  &__subtitle {
    font-size: 16px;
    color: var(--color-black);
    margin-bottom: 12px;
  }

  &__button-wrapper {
    display: flex;
    justify-content: center;
  }

  &__button {
    margin-top: 24px;
    font-family: var(--font-family);
    padding: 12px 0 12px;
    background-color: var(--color-white);
  }

  &__loader {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 56px 0 72px;
  }

  &__calendar-wrapper {
    position: relative;
    border: 4px solid transparent;
    border-radius: 5px;
    padding: 15px 0;

    &.isNotSelect {
      animation: bounceBorder 1s ease-in-out infinite alternate;
    }
  }

  &__calendar-overlay {
    position: absolute;
    inset: 0;
    background-color: rgba(255, 255, 255, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__calendar {
    margin-bottom: 14px;
  }

  &__helper {
    display: flex;
    gap: 14px;
    width: 100%;
    justify-content: center;
    font-size: 12px;
    font-weight: 600;
    font-family: var(--font-family);
    white-space: nowrap;

    @media (min-width: 768px) {
      gap: 16px;
      font-size: 14px;
    }

    .m-gray {
      color: var(--color-gray-75);
    }
    .m-blue {
      color: var(--view-color-primary);
    }
    .m-red {
      color: var(--color-red-10);
    }
  }
}
</style>
