import * as path from "path";

const requireSvgIcon = require.context("@/assets/icons", false, /\.svg$/);

export const svgIconsCollection = requireSvgIcon
  .keys()
  .reduce((prev, filePath) => {
    const iconName = path.basename(filePath, ".svg");
    return {
      ...prev,
      [iconName]: requireSvgIcon(filePath)
    };
  }, {});
