<template>
  <div class="count-down">
    <p
      class="count-down__text"
      :class="[{ 'm-white': isWhite }]"
      v-if="formattedTime"
    >
      Time to complete booking: {{ formattedTime }}
    </p>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "CountDown",
  data() {
    return {
      currentTime: moment(),
      timer: null,
    };
  },
  props: {
    isWhite: {
      type: Boolean,
      default: false,
    },
    expiresAt: {
      type: Object,
    },
  },

  computed: {
    timeRemaining() {
      return moment(
        moment.utc(this.expiresAt?.seconds || this.expiresAt?._seconds, "X"),
      ).diff(this.currentTime);
    },
    formattedTime() {
      const duration = moment.duration(this.timeRemaining);

      if (duration._milliseconds > 0) {
        const minutes = duration.minutes();
        const seconds = duration.seconds();

        return `${minutes
          .toString()
          .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
      } else {
        console.log("reset", this.expiresAt);
        clearInterval(this.timer);
        this.$emit("reset");
        return "";
      }
    },
  },
  mounted() {
    this.timer = setInterval(() => {
      this.currentTime = moment();
    }, 1000);
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>

<style lang="scss" scoped>
.count-down {
  font-family: var(--font-family);
  &__text {
    font-size: 12px;
    font-weight: 500;
    line-height: 12px;
    color: var(--color-gray-100);

    &.m-white {
      color: var(--color-white);
    }
  }
}
</style>
