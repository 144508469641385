<template>
  <div class="bookingProductModal" :class="{ 'm-small': isBadAxeWidget }">
    <div
      v-if="isProductsLoading || hasDefaultProduct"
      class="bookingProductModal__loader"
    >
      <Loader :color="isBadAxeWidget ? 'red' : 'white'" size="l" />
    </div>
    <div
      :class="[
        'bookingProductModal__list',
        {
          'm-grid': isBadAxeWidget,
          'm-fullsize':
            isBadAxeWidget &&
            filteredProducts.length % 1 === 0 &&
            filteredProducts.length % 2 !== 0,
        },
      ]"
      v-else
    >
      <product-card
        class="bookingProductModal__item"
        :class="{
          'm-badaxe': isBadAxeWidget,
        }"
        v-for="(product, index) in filteredProducts"
        :key="index"
        :name="product.name"
        :title="product.title"
        :description="product.description"
        :image="product.image"
        :is-deposit="
          !!product.deposit && product.deposit !== '' && product.deposit !== '0'
        "
        @click.native="() => handleProductClick(product)"
      />
    </div>
  </div>
</template>
<script>
import { mapActions, mapMutations, mapState, mapGetters } from "vuex";
import { Components } from "@zteam/booking-axe-sb";
import { GlobalService } from "@/api/services/GlobalService";
import { BookingInteractionEnum } from "@/api/types";

export default {
  name: "BookingProductModal",
  components: {
    ProductCard: Components.ProductCard,
  },

  computed: {
    ...mapState({
      products: (state) => state.products,
      isProductsLoading: (state) => state.isProductsLoading,
      interactionClientId: (state) => state.interactionClientId,
      venue: (state) => state.venue,
      widgetTemplate: (state) => state.widgetTemplate,
    }),
    ...mapGetters({
      isBadAxeWidget: "isBadAxeWidget",
    }),
    filteredProducts() {
      if (this.isBadAxeWidget) {
        return this.products.filter(
          (product) => product.groups && !!product.groups.length,
        );
      } else {
        return this.products;
      }
    },
    hasDefaultProduct() {
      return (
        this.venue.defaultWidgetTemplateId && this.widgetTemplate.productId
      );
    },
  },
  created() {
    this.setPlayersCount(null);
  },
  methods: {
    ...mapActions(["getProductInfo", "getProducts"]),
    ...mapMutations({
      setProductId: "SET_PRODUCT_ID",
      setPlayersCount: "SET_PLAYERS_COUNT",
    }),
    async handleProductClick({ id }) {
      this.setProductId(id);

      try {
        await this.getProductInfo();
        this.$emit("product-selected");
        GlobalService.sendInteraction({
          interaction: BookingInteractionEnum.SELECT_PRODUCT,
          clientId: this.interactionClientId,
          productId: id,
        });
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.bookingProductModal {
  padding: 0 20px 64px;

  &.m-small {
    padding: 0 12px 24px;

    @media (min-width: 768px) {
      padding: 0 20px 64px;
    }
  }

  &__loader {
    padding-top: 32px;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 20px;

    &.m-grid {
      display: flex;
      flex-direction: column;

      @media (min-width: 768px) {
        flex-direction: row;
        flex-wrap: wrap;
      }
    }

    &.m-fullsize {
      .bookingProductModal__item:last-child {
        width: 100%;
      }
    }
  }

  .m-badaxe {
    width: 100%;

    @media (min-width: 768px) {
      width: 50%;
    }
  }

  &__item {
    --color-secondary-500: var(--view-color-secondary);
  }
}
</style>
