<template>
  <custom-modal
    width="90%"
    classes="confirm-modal"
    :name="name"
    @before-close="dialog.cancel()"
  >
    <confirm
      :title="dialog.state.title"
      :message="dialog.state.message"
      :is-html="dialog.state.isHtml"
      :cancel-text="dialog.state.cancelText"
      :ok-text="dialog.state.okText"
      :variant="variant"
      @confirm="dialog.ok()"
      @cancel="dialog.cancel()"
    />
  </custom-modal>
</template>
<script>
import { Components } from "@zteam/booking-axe-sb";
import dialog from "@/helpers/dialog";
import { mapGetters } from "vuex";

export default {
  name: "GlobalDialog",
  components: {
    confirm: Components.Confirm
  },
  data() {
    return {
      dialog,
      name: "global-dialog"
    };
  },
  computed: {
    ...mapGetters({
      isBadAxe: "isBadAxeWidget"
    }),
    isActive() {
      return this.dialog.state.active;
    },
    variant() {
      return this.isBadAxe ? "danger" : "primary";
    }
  },
  watch: {
    isActive(newValue) {
      const action = newValue ? this.$modal.show : this.$modal.hide;
      action(this.name);
    }
  }
};
</script>
