<template xmlns:Checkbox="http://www.w3.org/1999/html">
  <div>
    <div class="time-slots" v-if="chosenDate">
      <div class="time-slots__info">
        <div class="time-slots__subtitle">Selected Date</div>
        <div class="time-slots__line"></div>
        <div class="time-slots__weekday">{{ weekday }}</div>
        <div class="time-slots__date">
          {{ fullMonth }} {{ selectedDate.date }}
        </div>
        <button class="time-slots__back" @click="$emit('back')">
          <Icon name="arrow-left" color="black" />
          Change Date
        </button>
      </div>
      <div class="time-slots__time">
        <div class="time-slots__subtitle">
          Select from Available time slots:
        </div>
        <time-slot
          class="time-slots__time-slots"
          v-if="showTimeSlots && !isDatesLoading && !isBookingCreating"
          :free-slots="freeSlots"
          :all-slots="getArrayWithUTCOffset(allSlots)"
          :selected-slots="getArrayWithUTCOffset(chosenSlots) || []"
          variant="secondary"
          :isSquare="false"
          :isPublic="false"
          :players-count="playersCount"
          :hours-format="hoursFormat"
          @select="handleSlotsSelect"
        />
        <div v-else class="time-slots__loader">
          <Loader
            v-if="isLoading || isDatesLoading || isBookingCreating"
            color="red"
            size="l"
          />
          <div v-else class="time-slots__emptyText">
            {{ $t("There is no slots for this date") }}
          </div>
        </div>
        <div
          v-if="product.terms"
          :class="[
            'time-slots__terms',
            { 'time-slots__terms--active': !isAcceptTerms }
          ]"
        >
          <Checkbox
            :is-disabled="isLoading || isDatesLoading || isBookingCreating"
            class="time-slots__terms-checkbox"
            v-model="isAcceptTerms"
            variant="tertiary"
          />
          <span class="time-slots__terms-text">
            <a class="time-slots__terms-link" @click.stop="openTerms">
              Agree to Cancellation and Minimum Booking Policy?
            </a>
          </span>
        </div>
        <div
          v-if="isLargeGroupDisclaimer"
          :class="[
            'time-slots__terms',
            { 'time-slots__terms--active': !isAcceptLargeGroupDisclaimer }
          ]"
        >
          <Checkbox
            :is-disabled="isLoading || isDatesLoading || isBookingCreating"
            class="time-slots__terms-checkbox"
            v-model="isAcceptLargeGroupDisclaimer"
            variant="tertiary"
          />
          <span class="time-slots__terms-text">
            <a
              class="time-slots__terms-link"
              @click.stop="openDisclaimer(largeGroupDisclaimerModalName)"
            >
              Large Group Disclaimer
            </a>
          </span>
        </div>
        <div
          v-if="isHolidayDisclaimer"
          :class="[
            'time-slots__terms',
            { 'time-slots__terms--active': !isAcceptHolidayDisclaimer }
          ]"
        >
          <Checkbox
            :is-disabled="isLoading || isDatesLoading || isBookingCreating"
            class="time-slots__terms-checkbox"
            v-model="isAcceptHolidayDisclaimer"
            variant="tertiary"
          />
          <span class="time-slots__terms-text">
            <a
              class="time-slots__terms-link"
              @click.stop="openDisclaimer(holidayDisclaimerModalName)"
            >
              Holiday Disclaimer
            </a>
          </span>
        </div>
      </div>
      <div class="time-slots__policy">
        <p>{{ product && product.widgetNote ? product.widgetNote : "" }}</p>
        <p>
          <span class="m-red m-bold">Cancellation Policy:</span>
          {{ cancellationText }}
        </p>
      </div>
      <TermsModal
        v-if="product.terms"
        :name="termsModalName"
        :text="sanitize(product.terms.text)"
        @click="isAcceptTerms = true"
      />
      <DisclaimerModal
        v-if="isLargeGroupDisclaimer"
        :name="largeGroupDisclaimerModalName"
        title="LARGE GROUPS"
        :text="sanitize(product.largeGroupDisclaimer.text)"
        @click="isAcceptLargeGroupDisclaimer = true"
      />
      <DisclaimerModal
        v-if="isHolidayDisclaimer"
        :name="holidayDisclaimerModalName"
        title="HOLIDAY"
        :text="sanitize(product.holidayDisclaimer.text)"
        @click="isAcceptHolidayDisclaimer = true"
      />
    </div>
    <div class="time-slots__button-wrapper">
      <Button
        class="time-slots__button"
        variant="danger"
        :is-disabled="
          isLoading ||
            isDatesLoading ||
            isBookingCreating ||
            !isChosenSlots ||
            (product.terms && !isAcceptTerms) ||
            (isLargeGroupDisclaimer && !isAcceptLargeGroupDisclaimer) ||
            (isHolidayDisclaimer && !isAcceptHolidayDisclaimer)
        "
        @click="handleSubmit"
      >
        Next
      </Button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import {
  getMinutes,
  getSlotFromMinutes,
  getTimezoneOffsetHours,
  getUTCDate,
  getVenueTimeFormatHours,
  sanitize
} from "@/helpers/utils";
import moment from "moment/moment";
import { GlobalService } from "@/api/services/GlobalService";
import { BookingInteractionEnum } from "@/api/types";
import { MONTHS } from "@/helpers/const";
import TermsModal from "@/components/widget-old/TermsModal";
import DisclaimerModal from "@/components/badaxe/DisclaimerModal";

export default {
  name: "TimeSlots",
  components: { TermsModal, DisclaimerModal },
  data: () => ({
    MONTHS,
    isBookingCreating: false,
    isDatesLoading: false,
    isPlayersCountInteractionSent: false,
    isAcceptTerms: false,
    isAcceptLargeGroupDisclaimer: false,
    isAcceptHolidayDisclaimer: false,
    termsModalName: "termsModal",
    largeGroupDisclaimerModalName: "largeGroupDisclaimerModal",
    holidayDisclaimerModalName: "holidayDisclaimerModal"
  }),
  computed: {
    ...mapState({
      isLoading: state => state.isFreeSlotsLoading,
      slots: state => state.freeSlots,
      playersCount: state => state.playersCount,
      allSlots: state => state.allSlots,
      chosenSlots: state => state.chosenSlots,
      chosenDate: state => state.chosenDate,
      product: state => state.product,
      booking: state => state.booking,
      venue: state => state.venue,
      interactionClientId: state => state.interactionClientId,
      selectedGroup: state => state.selectedGroup
    }),
    isLargeGroupDisclaimer() {
      return (
        this.selectedGroup?.value.isLargeGroup &&
        this.product.largeGroupDisclaimer
      );
    },
    isHolidayDisclaimer() {
      return (
        this.product.holidayDisclaimer &&
        this.product.holidays?.includes(
          moment(this.chosenDate).format("YYYY-MM-DD")
        )
      );
    },
    isChosenSlots() {
      return this.chosenSlots.length > 0;
    },
    cancellationText() {
      if (
        this.product.cancellationPeriodAmount &&
        this.product.cancellationPeriodUnit
      ) {
        const cancellationPeriodUnit =
          +this.product.cancellationPeriodAmount === 1
            ? this.product.cancellationPeriodUnit
            : `${this.product.cancellationPeriodUnit}s`;
        return `We require ${this.product.cancellationPeriodAmount} ${cancellationPeriodUnit} notice to modify/reschedule events. Cancellations made with at
        least ${this.product.cancellationPeriodAmount} ${cancellationPeriodUnit} notice can be used as credit towards a future date, which
        is valid for 60 days.`;
      }
      return null;
    },
    fullMonth() {
      return MONTHS[this.selectedDate.month];
    },
    weekday() {
      return moment(this.chosenDate).format("dddd");
    },
    hoursFormat() {
      return getVenueTimeFormatHours();
    },
    showTimeSlots() {
      return !this.isLoading;
    },
    freeSlots() {
      return this.booking?.slots?.length
        ? [
            ...this.getArrayWithUTCOffset(this.booking.slots),
            ...this.getArrayWithUTCOffset(this.slots)
          ]
        : this.getArrayWithUTCOffset(this.slots);
    },
    selectedDate() {
      if (!this.chosenDate) return null;

      const date = new Date(moment(this.chosenDate, "YYYY-MM-DD"));

      return {
        date: date.getDate(),
        month: date.getMonth(),
        year: date.getFullYear()
      };
    }
  },
  async created() {
    await this.handleDateSelect();
  },
  mounted() {
    this.setChosenSlots([]);
    // this.openTerms();
  },
  methods: {
    sanitize,
    ...mapActions({
      getFreeSlots: "getFreeSlots",
      createBooking: "createBooking",
      updateBooking: "updateBooking"
    }),
    ...mapMutations({
      setChosenSlots: "SET_CHOSEN_SLOTS"
    }),
    openTerms() {
      this.$modal.hideAll();
      this.$modal.show(this.termsModalName);
    },
    openDisclaimer(modalName) {
      this.$modal.hideAll();
      this.$modal.show(modalName);
    },
    getArrayWithUTCOffset(array) {
      return (
        array?.map(slot => {
          const utcOffset = getTimezoneOffsetHours(this.venue.timezone);
          return {
            ...slot,
            fixedPrice: this.selectedGroup.value.fixedPrice,
            from: utcOffset
              ? getSlotFromMinutes(getMinutes(slot.from) + utcOffset * 60)
              : slot.from
          };
        }) || []
      );
    },
    async handleDateSelect() {
      if (this.chosenDate) {
        await this.getFreeSlots({
          date: getUTCDate(this.chosenDate),
          playersCount: this.playersCount
        });
      }
    },
    handleSlotsSelect(slots) {
      this.setChosenSlots(
        slots
          .map(slot => {
            const utcOffset = getTimezoneOffsetHours(this.venue.timezone);
            return {
              ...slot,
              from: getSlotFromMinutes(getMinutes(slot.from) - utcOffset * 60)
            };
          })
          .sort((a, b) => getMinutes(a.from) - getMinutes(b.from))
      );
    },
    async handleSubmit() {
      if (this.product.terms) {
        if (!this.isAcceptTerms || this.chosenSlots.length === 0) {
          return;
        }
      } else if (this.chosenSlots.length === 0) {
        return;
      }

      this.isBookingCreating = true;

      try {
        if (this.booking) {
          try {
            await this.updateBooking({
              playersCount: this.selectedGroup?.value.maximum
            });
          } catch {
            await this.createBooking({
              playersCount: this.selectedGroup?.value.maximum
            });
          }
        } else {
          await this.createBooking({
            playersCount: this.selectedGroup?.value.maximum
          });
        }
        this.$emit("submit");
        GlobalService.sendInteraction({
          interaction: BookingInteractionEnum.SELECT_SLOTS,
          clientId: this.interactionClientId,
          productId: this.product?.id
        });
      } finally {
        this.isBookingCreating = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@keyframes fade {
  from {
    background-color: rgba(238, 44, 44, 1);
  }
  to {
    background-color: rgba(238, 44, 44, 0);
  }
}

.time-slots {
  display: flex;
  flex-direction: column;
  gap: 32px;

  @media (min-width: 768px) {
    display: grid;
    column-gap: 40px;
    row-gap: 32px;
    grid-template-columns: repeat(2, 1fr);
  }

  &__button-wrapper {
    display: flex;
    justify-content: center;
  }

  &__button {
    margin-top: 24px;
    font-family: var(--font-family);
    padding: 12px 0 12px;
    background-color: var(--color-white);
  }

  &__info {
    padding: 0 16px;
  }

  &__time {
    display: flex;
    flex-direction: column;
    padding: 0 16px;
    border-left: 2px solid var(--color-secondary-300);
  }

  &__time-slots {
    padding: 0;
  }

  &__subtitle {
    font-family: var(--font-family);
    font-size: 16px;
    margin-bottom: 8px;
  }

  &__loader {
    margin-top: 16px;
    margin-bottom: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__line {
    border: 2px solid var(--color-black);
    width: 50px;
    margin: 16px 0;
  }

  &__date {
    display: flex;
    align-items: flex-end;
    font-size: 38px;
    font-weight: 600;
    line-height: 48px;
    white-space: nowrap;
    font-family: var(--font-family);
    margin-bottom: 32px;

    @media (min-width: 768px) {
      font-size: 48px;
    }
  }

  &__back {
    color: var(--color-black);
    font-family: var(--font-family);
    font-size: 20px;
    display: flex;
    gap: 5px;
    border: none;
    background-color: transparent;
    padding: 0;
    cursor: pointer;
    margin-left: -29px;
  }

  &__weekday {
    color: var(--color-red-10);
    font-size: 24px;
    font-weight: 600;
  }

  &__terms {
    display: flex;
    align-items: center;
    grid-gap: 12px;
    gap: 12px;
    margin-top: auto;
    padding: 10px 5px;
    border-radius: 5px;

    &--active {
      background-color: var(--color-danger);
      animation: fade 1.5s infinite ease-in-out alternate;
    }

    &:not(:last-of-type) {
      margin-bottom: 12px;
    }
  }

  &__terms-checkbox {
    margin-top: 2px;
  }

  &__terms-text {
    font-weight: 500;
    font-size: 14px;
    color: var(--color-black);
    font-family: var(--font-family);
  }

  &__terms-link {
    color: inherit;
    text-decoration: underline;
    cursor: pointer;
  }

  &__policy {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    font-family: var(--font-family);
    font-size: 12px;
    gap: 8px;
    grid-column: 1/3;

    .m-red {
      color: var(--color-red-10);
    }

    .m-bold {
      font-weight: 600;
    }
  }
}
</style>
