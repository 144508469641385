<template>
  <CustomModal
    name="success-modal"
    :adaptive="true"
    :clickToClose="false"
    classes="m-success-modal"
  >
    <div class="successModal" v-if="booking && product && venue">
      <div class="successModal__close">
        <Icon
          name="close"
          color="secondary-500"
          is-clickable
          @click="handleClose"
        />
      </div>
      <div class="successModal__block">
        <Title class="successModal__title" :level="1" color="secondary-500">
          {{ $t("Success") }}!
        </Title>
        <div class="successModal__text">
          {{ $t("Your registration code is") }} {{ booking.registrationCode }}
          <br />
          {{ $t("You booked the") }} {{ product.title }} {{ $t("on") }}
        </div>
        <div
          class="successModal__text m-time"
          v-if="formattedDate && formattedSlots"
        >
          {{ formattedDate }} {{ formattedSlots }}
        </div>
      </div>
      <div
        :class="['successModal__block', { 'm-single': !booking.activeWaiver }]"
      >
        <!-- <template v-if="booking.activeWaiver">
          <div class="successModal__textSecond">
            {{ $t("Friend joining you? Have them sign as well") }}
          </div>
          <div class="successModal__button">
            <icon-button
              icon="link"
              is-raised
              label-position="bottom"
              @click="handleCopyWaiversLink"
            >
              {{ $t("Copy URL") }}
            </icon-button>
          </div>
        </template> -->
        <div
          v-if="booking.activeWaiver && !isBadAxeWidget"
          class="successModal__buttonWaivers"
        >
          <Button variant="primary" is-block @click="handleSignClick">
            {{ $t("Sign Your Waiver") }}
          </Button>
        </div>
        <Button
          :variant="
            isBadAxeWidget && !booking.activeWaiver
              ? 'danger'
              : isBadAxeWidget
              ? 'secondary'
              : 'primary'
          "
          is-block
          @click="handleResetBooking"
        >
          {{ isBadAxeWidget ? $t("Continue") : $t("Make another booking") }}
        </Button>
      </div>
    </div>
  </CustomModal>
</template>
<script>
import { getFormattedBookingTime, getFormattedSlots } from "@/helpers/utils";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import Cookies from "js-cookie";

export default {
  name: "SuccessModal",
  computed: {
    ...mapState({
      booking: state => state.booking,
      product: state => state.product,
      productId: state => state.productId,
      venue: state => state.venue,
      interactionClientId: state => state.interactionClientId,
      isBaseProductId: state => state.isBaseProductId
    }),
    ...mapGetters({
      isBadAxeWidget: "isBadAxeWidget"
    }),
    formattedDate() {
      return getFormattedBookingTime(this.booking);
    },
    formattedSlots() {
      return `at ${getFormattedSlots(
        this.booking,
        this.venue.timezone,
        this.product.breakTime,
        this.product.duration
      )}`;
    }
  },
  async created() {
    if (!this.isBadAxeWidget) {
      const { bookingId, productId } = this.$route.query;

      if (productId) {
        this.setProductId(productId || "");
        await this.getProductInfo();
      }

      if (bookingId)
        await this.getBooking({
          bookingId,
          productId
        });
    }
  },
  methods: {
    ...mapActions(["getProductInfo", "getProducts", "getBooking"]),
    ...mapMutations({
      setBooking: "SET_BOOKING",
      setProduct: "SET_PRODUCT_INFO",
      setProductId: "SET_PRODUCT_ID",
      setReservationInfo: "SET_RESERVATION_INFO",
      setBookingId: "SET_BOOKING_ID"
    }),
    async handleCopyWaiversLink() {
      const link = `${process.env.VUE_APP_WAIVERS_LINK}?venueId=${Cookies.get(
        "yoreferee_venueId"
      )}&productId=${this.product.id}&bookingId=${this.booking.id}`;
      await navigator.clipboard.writeText(link);
    },
    handleSignClick() {
      const url = new URL(process.env.VUE_APP_WAIVERS_LINK);
      url.searchParams.set("venueId", this.venue.id);
      url.searchParams.set("productId", this.product.id);
      url.searchParams.set("bookingId", this.booking.id);
      url.searchParams.set("widgetTemplateId", this.widgetTemplate.id);
      window.open(url, "_blank");
    },
    handleResetBooking() {
      this.setBooking(null);
      this.setProduct(null);
      this.setBookingId(null);
      this.setReservationInfo({
        firstName: "",
        lastName: "",
        phone: "",
        email: "",
        customFields: {}
      });

      this.isBadAxeWidget
        ? this.$router.replace({ query: {} })
        : this.$router.replace("/");
      const productId = this.productId;
      this.setProductId(null);
      this.$modal.hide("success-modal");
      this.$nextTick(async () => {
        this.setProductId(productId);
        if (this.isBaseProductId) await this.getProductInfo();
        else await this.getProducts();

        if (!this.isBadAxeWidget) {
          window.postMessage("modal-show", "*");
          this.$modal.show("book-modal");
        }
      });
      if (this.isBadAxeWidget) {
        window.location.assign(process.env.VUE_APP_BAD_AXE_LEAGUE);
      }
    },
    handleClose() {
      if (this.isBadAxeWidget) {
        this.setBookingId(null);
        if (this.isBadAxeWidget) {
          window.location.assign(process.env.VUE_APP_BAD_AXE_LEAGUE);
        }
        this.$modal.hide("success-modal");
      } else {
        window.parent.postMessage("modal-hide", "*");
      }
    }
  }
};
</script>
<style lang="scss">
.vm--container {
  .vm--modal.m-success-modal {
    box-shadow: none;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 24px;
    top: 50% !important;
    transform: translateY(-50%);
  }
}

.successModal {
  background: var(--color-white);
  border-radius: 20px;
  padding: 40px 16px 32px;
  position: relative;

  &__close {
    position: absolute;
    top: 24px;
    right: 24px;
  }

  &__block {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    &:not(:last-child) {
      margin-bottom: 40px;
    }

    &:first-child {
      margin-bottom: 24px;
      padding-bottom: 40px;
      border-bottom: 1px solid var(--color-secondary-300);
    }

    &.m-single {
      border-bottom: none;
      margin-top: 32px;
    }
  }

  &__title {
    font-weight: 600;
    margin-bottom: 20px;
  }

  &__titleWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 32px;
  }

  &__text {
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: var(--color-secondary-500);

    &.m-time {
      font-weight: 600;
      color: var(--color-orange);
    }
  }

  &__textSecond {
    font-weight: 400;
    font-size: 14px;
    line-height: 28px;
    color: var(--color-secondary-500);
    margin-bottom: 16px;
  }

  &__button {
    margin-bottom: 32px;
  }

  button:not(:disabled) {
    background-color: var(--view-color-primary) !important;
    &:not(:disabled) {
      &:hover,
      &:active {
        background-color: var(--view-color-primary) !important;
        filter: brightness(110%);
      }
    }
  }

  &__buttonWaivers {
    width: 100%;
    margin-bottom: 16px;

    button:not(:disabled) {
      background-color: var(--view-color-secondary) !important;
      &:not(:disabled) {
        &:hover,
        &:active {
          background-color: var(--view-color-secondary) !important;
          filter: brightness(110%);
        }
      }
    }
  }
}
</style>
