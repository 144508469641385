import { render, staticRenderFns } from "./PaymentExpired.vue?vue&type=template&id=0eb8e28e&scoped=true&"
import script from "./PaymentExpired.vue?vue&type=script&lang=js&"
export * from "./PaymentExpired.vue?vue&type=script&lang=js&"
import style0 from "./PaymentExpired.vue?vue&type=style&index=0&id=0eb8e28e&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0eb8e28e",
  null
  
)

export default component.exports