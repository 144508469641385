import { WidgetThemeEnum } from "../helpers/interfaces";

export const getters = {
  isBadAxeWidget(state: any) {
    return (
      state.widgetTheme === WidgetThemeEnum.BADAXE ||
      state.venue?.widget === WidgetThemeEnum.BADAXE
    );
  },
  isYoreferee2Widget(state: any) {
    return (
      state.widgetTheme === WidgetThemeEnum.YOREFEREE2 ||
      state.venue?.widget === WidgetThemeEnum.YOREFEREE2
    );
  },
};
