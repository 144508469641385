import { ServiceBase } from "@/api/core";
import {
  IReservationInfo,
  ICheckoutRequest,
  ICheckoutSession,
  ISignature,
  IUploadMedia,
  IProduct,
  IWaiver,
  ISlot,
  IBooking,
  IDates,
  IGroup
} from "@/helpers/interfaces";
import { IBookingInteractionPayload } from "../types";

let priceAbortController: AbortController;
const datesAbortControllerMap: Record<string, AbortController> = {};
let slotsAbortController: AbortController;

export class GlobalService extends ServiceBase {
  public static getProducts(): Promise<IProduct[]> {
    const url = `/product`;
    return this.callApi({
      method: "GET",
      url
    });
  }

  public static getProductInfo(productId: string): Promise<IProduct> {
    const url = `/product/${productId}`;
    return this.callApi({
      method: "GET",
      url
    });
  }

  public static getVenue(): Promise<void> {
    const url = `/venue`;
    return this.callApi({
      method: "GET",
      url
    });
  }
  public static getWidgetTemplate(id: string): Promise<void> {
    const url = `/widget-template/${id}`;
    return this.callApi({
      method: "GET",
      url
    });
  }

  public static getFreeSlots({
    productId,
    date,
    playersCount
  }: {
    productId: string;
    date: string;
    playersCount: number;
  }): Promise<{ freeSlots: ISlot[]; allSlots: ISlot[] }> {
    if (slotsAbortController) {
      slotsAbortController.abort();
    }
    slotsAbortController = new AbortController();

    const url = `/product/${productId}/slots`;

    return this.callApi({
      method: "GET",
      url,
      params: {
        date,
        playersCount
      },
      signal: slotsAbortController.signal
    });
  }

  public static getBooking({
    bookingId
  }: {
    bookingId: string;
  }): Promise<IBooking> {
    const url = `/booking/${bookingId}`;

    return this.callApi({
      method: "GET",
      url
    });
  }

  public static createBooking({
    productId,
    date,
    playersCount,
    slots,
    selectedGroupId,
    selectedGroup
  }: {
    productId: string;
    date: string;
    playersCount: number;
    slots: number[];
    selectedGroupId?: string | number;
    selectedGroup?: IGroup;
  }): Promise<IBooking> {
    const url = `/booking`;
    return this.callApi({
      method: "POST",
      url,
      data: {
        productId,
        date,
        slots,
        playersCount,
        ...(selectedGroupId && selectedGroup
          ? { selectedGroupId, selectedGroup }
          : {})
      }
    });
  }

  public static deleteBooking(
    bookingId: string
  ): Promise<{ bookingId: string; message: string }> {
    const url = `/booking/${bookingId}`;

    return this.callApi({
      method: "DELETE",
      url
    });
  }

  public static getWaiverInfo(productId: string): Promise<IWaiver> {
    const url = `/product/${productId}/waiver`;

    return this.callApi({
      method: "GET",
      url
    });
  }

  public static updateBooking({
    productId,
    bookingId,
    date,
    playersCount,
    slots
  }: {
    productId: string;
    bookingId: string;
    date: string;
    slots: number[];
    playersCount: number;
  }): Promise<IBooking> {
    const url = `/booking/${bookingId}/time`;

    return this.callApi({
      method: "PATCH",
      url,
      data: {
        productId,
        date,
        slots,
        playersCount
      }
    });
  }

  public static addReservationInfo({
    bookingId,
    data
  }: {
    bookingId: string;
    data: IReservationInfo;
  }): Promise<IBooking> {
    const url = `/booking/${bookingId}/reservationInfo`;

    return this.callApi({
      method: "PATCH",
      url,
      data
    });
  }

  public static checkout({
    productId,
    bookingId,
    data
  }: {
    productId: string;
    bookingId: string;
    data: ICheckoutRequest;
  }): Promise<ICheckoutSession> {
    const url = `/booking/${bookingId}/checkout`;
    return this.callApi({
      method: "POST",
      url,
      data: {
        ...data,
        productId
      }
    });
  }

  public static createSignature({
    productId,
    data
  }: {
    productId: string;
    data: ISignature;
  }): Promise<ICheckoutSession> {
    const url = `/product/${productId}/signature`;
    return this.callApi({
      method: "POST",
      url,
      data
    });
  }

  public static getPrice({
    productId,
    slots,
    playersCount,
    upsellItems,
    isDeposit,
    selectedGroupId,
    promocode
  }: {
    productId: string;
    isDeposit: boolean;
    playersCount: number;
    slots: ISlot[];
    upsellItems: string[];
    selectedGroupId?: string;
    promocode?: string;
  }): Promise<ICheckoutSession> {
    if (priceAbortController) {
      priceAbortController.abort();
    }
    priceAbortController = new AbortController();

    const url = `/product/${productId}/price`;

    return this.callApi({
      method: "GET",
      url,
      params: {
        isDeposit,
        playersCount,
        slots: slots.map(slot => slot.id),
        upsellItems,
        ...(selectedGroupId ? { selectedGroupId } : {}),
        promocode
      },
      signal: priceAbortController.signal
    });
  }

  public static getDates({
    productId,
    bookingId,
    month,
    playersCount
  }: {
    productId: string;
    bookingId?: string;
    playersCount: number;
    month: string; // YYYY-MM
  }): Promise<IDates> {
    const requestKey = `${playersCount}-${month}`;
    if (datesAbortControllerMap[requestKey]) {
      datesAbortControllerMap[requestKey].abort();
    }
    datesAbortControllerMap[requestKey] = new AbortController();

    const url = `/product/${productId}/dates`;
    return this.callApi({
      method: "GET",
      url,
      params: {
        bookingId,
        playersCount,
        month
      },
      signal: datesAbortControllerMap[requestKey].signal
    });
  }

  public static uploadMedia(data: IUploadMedia): Promise<{ url: string }> {
    const url = "/media/images/upload";
    return this.callApi({
      method: "POST",
      url,
      data
    });
  }

  public static sendInteraction(data: IBookingInteractionPayload) {
    try {
      const url = "/booking/interactions";
      return this.callApi({
        method: "POST",
        url,
        data
      });
    } catch (err) {
      console.log("Send interaction error > ", err);
    }
  }
}
