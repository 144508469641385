<template>
  <div v-if="!isBadAxeWidget">
    <SuccessModalOld v-if="!isYoreferee2Widget" />
    <component
      :is="isYoreferee2Widget ? BookButton : BookButtonOld"
      v-if="position === WidgetPositionEnum.FLOATING && !isHideBeacon"
      @click.native="handleButtonClick"
    />
    <iframe
      ref="iframe"
      allow="clipboard-write; payment"
      :style="{
        width: '1px',
        'min-width': '100%',
        border: 'none',
        ...(position === WidgetPositionEnum.FLOATING
          ? {
              width: 0,
              height: 0,
              opacity: 0,
              display: 'none',
              'z-index': 1000,
              position: 'fixed',
              left: 0,
              top: 0,
              ...(isIframeOpen
                ? {
                    width: '100vw',
                    height: '100vh',
                    opacity: 1,
                    display: 'block'
                  }
                : {})
            }
          : {})
      }"
      :src="iframeLink"
      v-resize="{ active: position === WidgetPositionEnum.EMBEDDED }"
      @load="handleIframeLoaded"
    />
    <global-dialog />
  </div>
  <div v-else @click="sendOpenInteraction">
    <SuccessModalOld />
    <badaxe />
    <global-dialog />
  </div>
</template>

<script>
import BookButtonOld from "@/components/widget-old/BookButton.vue";
import BookButton from "@/components/widget/BookButton.vue";
import {
  BookingStatusEnum,
  WidgetPositionEnum,
  WidgetThemeEnum
} from "@/helpers/interfaces";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { GlobalService } from "@/api/services/GlobalService";
import { BookingInteractionEnum } from "@/api/types";
import Cookies from "js-cookie";
import SuccessModalOld from "@/components/widget-old/SuccessModal";
import GlobalDialog from "@/components/GlobalDialog";
import Badaxe from "@/components/badaxe/Badaxe";

export default {
  name: "App",
  components: {
    SuccessModalOld,
    GlobalDialog,
    Badaxe
  },
  data: () => ({
    BookButton,
    BookButtonOld,
    SuccessModalOld,
    isIframeLoaded: false,
    isPaymentSuccess: false,
    isPaymentError: false,
    BookingStatusEnum,
    WidgetThemeEnum,
    WidgetPositionEnum,
    isIframeOpen: false
  }),
  computed: {
    ...mapState({
      venue: state => state.venue,
      venueId: state => state.venueId,
      widgetTemplate: state => state.widgetTemplate,
      widgetTemplateId: state => state.widgetTemplateId,
      widgetView: state => state.widgetView,
      productId: state => state.productId,
      bookingId: state => state.bookingId,
      position: state => state.position,
      isHideBeacon: state => state.isHideBeacon,
      isPromocodesAvailable: state => state.isPromocodesAvailable,
      interactionClientId: state => state.interactionClientId,
      isAlwaysOpen: state => state.isAlwaysOpen
    }),
    ...mapGetters({
      isBadAxeWidget: "isBadAxeWidget",
      isYoreferee2Widget: "isYoreferee2Widget"
    }),
    iframeLink() {
      return `${process.env.VUE_APP_IFRAME_BASE_URL}/iframe/index.html`;
    },
    isCanShowSuccess() {
      return this.isIframeLoaded && this.isPaymentSuccess;
    },
    isCanShowError() {
      return this.isIframeLoaded && this.isPaymentError;
    }
  },
  watch: {
    isCanShowSuccess(val) {
      if (val) {
        this.postSuccessToIframe();
      }
    },
    isCanShowError(val) {
      if (val) {
        this.postErrorToIframe();
      }
    }
  },
  async created() {
    const baseUrl = process.env.BASE_URL;
    if (baseUrl) {
      const elems = document.querySelectorAll(`a[href^='${baseUrl}']`);
      if (elems?.length) {
        elems.forEach(elem => {
          elem?.addEventListener("click", this.handleButtonClick);
          elem?.removeAttribute("href");
        });
      }
    }

    const widgetTheme = Cookies.get("yoreferee_widget");
    this.SET_WIDGET_THEME(widgetTheme || WidgetThemeEnum.YOREFEREE2);
    if (widgetTheme === WidgetThemeEnum.BADAXE) {
      await this.getProducts();
    }
  },
  async mounted() {
    if (document) {
      window.addEventListener(
        "message",
        event => {
          if (event.data === "modal-hide" && !this.isAlwaysOpen) {
            this.isIframeOpen = false;
          }
        },
        false
      );
      window.addEventListener(
        "message",
        async event => {
          if (event.data?.call === "checkout") {
            window.open(event.data.value, "_self");
          }
        },
        false
      );
      window.addEventListener(
        "message",
        async event => {
          if (event.data === "idle") {
            if (this.isIframeLoaded && this.isIframeOpen) {
              this.$refs.iframe.src += "";
              if (this.position === WidgetPositionEnum.FLOATING) {
                this.isIframeLoaded = false;
                this.isIframeOpen = false;
              }
            }
          }
        },
        false
      );
    }

    const { bookingPaymentStatus, ...restQuery } = this.$route.query;
    if (bookingPaymentStatus === "success") {
      this.isPaymentSuccess = true;
      GlobalService.sendInteraction({
        interaction: BookingInteractionEnum.PAYMENT_SUCCESS,
        clientId: this.interactionClientId,
        productId: this.productId
      });
      this.$modal.show("success-modal");
    } else if (bookingPaymentStatus === "error") {
      this.isPaymentError = true;
    }

    if (bookingPaymentStatus) {
      await this.$router.replace({ path: this.$route.path, query: restQuery });
    }
  },
  methods: {
    ...mapActions(["getBooking", "getProducts", "getVenue", "checkout"]),
    ...mapMutations({
      SET_WIDGET_THEME: "SET_WIDGET_THEME",
      SET_PRODUCT_INFO: "SET_PRODUCT_INFO",
      SET_BOOKING_ID: "SET_BOOKING_ID"
    }),
    postSuccessToIframe() {
      const iframe = this.$refs.iframe;
      this.isIframeOpen = true;
      this.postSendValueToIframe({
        isOpenModal: false
      });

      iframe.contentWindow.postMessage("modal-success-show", "*");
    },
    postErrorToIframe() {
      const iframe = this.$refs.iframe;
      this.isIframeOpen = true;

      iframe.contentWindow.postMessage("modal-error-show", "*");
    },
    postSendValueToIframe({ isOpenModal }) {
      const iframe = this.$refs.iframe;
      iframe.contentWindow.postMessage(
        {
          call: "sendValue",
          value: {
            venue: this.venue,
            venueId: this.venueId,
            widgetTemplate: this.widgetTemplate,
            widgetTemplateId: this.widgetTemplateId,
            widgetView: this.widgetView,
            productId: this.productId,
            bookingId: this.bookingId,
            position: this.position,
            isPromocodesAvailable: this.isPromocodesAvailable,
            interactionClientId: this.interactionClientId,
            isOpenModal
          }
        },
        "*"
      );
    },
    handleButtonClick() {
      if (!this.isIframeOpen) {
        this.postSendValueToIframe({
          isOpenModal: true
        });
        this.isIframeOpen = true;
        this.sendOpenInteraction();
      }
    },
    handleIframeLoaded() {
      this.isIframeLoaded = true;
      if (this.position === WidgetPositionEnum.EMBEDDED || this.isAlwaysOpen) {
        this.handleButtonClick();
      }
    },
    sendOpenInteraction() {
      if (!this.$store.state.isOpenInteractionSent) {
        try {
          this.$store.commit("SET_IS_OPEN_INTERACTION_SENT", true);
          const interactions = [{ interaction: BookingInteractionEnum.OPEN }];
          if (this.$store.state.productId) {
            interactions.push({
              interaction: BookingInteractionEnum.SELECT_PRODUCT,
              productId: this.$store.state.productId
            });
          }

          setTimeout(async () => {
            await Promise.all(
              interactions.map(interaction =>
                GlobalService.sendInteraction({
                  interaction: interaction.interaction,
                  clientId: this.interactionClientId,
                  productId: interaction.productId
                })
              )
            );
          }, 2000);
        } catch {
          this.$store.commit("SET_IS_OPEN_INTERACTION_SENT", false);
        }
      }
    }
  }
};
</script>
