var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.isBadAxeWidget)?_c('div',[(!_vm.isYoreferee2Widget)?_c('SuccessModalOld'):_vm._e(),(_vm.position === _vm.WidgetPositionEnum.FLOATING && !_vm.isHideBeacon)?_c(_vm.isYoreferee2Widget ? _vm.BookButton : _vm.BookButtonOld,{tag:"component",nativeOn:{"click":function($event){return _vm.handleButtonClick.apply(null, arguments)}}}):_vm._e(),_c('iframe',{directives:[{name:"resize",rawName:"v-resize",value:({ active: _vm.position === _vm.WidgetPositionEnum.EMBEDDED }),expression:"{ active: position === WidgetPositionEnum.EMBEDDED }"}],ref:"iframe",style:({
      width: '1px',
      'min-width': '100%',
      border: 'none',
      ...(_vm.position === _vm.WidgetPositionEnum.FLOATING
        ? {
            width: 0,
            height: 0,
            opacity: 0,
            display: 'none',
            'z-index': 1000,
            position: 'fixed',
            left: 0,
            top: 0,
            ...(_vm.isIframeOpen
              ? {
                  width: '100vw',
                  height: '100vh',
                  opacity: 1,
                  display: 'block'
                }
              : {})
          }
        : {})
    }),attrs:{"allow":"clipboard-write; payment","src":_vm.iframeLink},on:{"load":_vm.handleIframeLoaded}}),_c('global-dialog')],1):_c('div',{on:{"click":_vm.sendOpenInteraction}},[_c('SuccessModalOld'),_c('badaxe'),_c('global-dialog')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }