import moment from "moment";
import {
  IBooking,
  ICheckoutInfo,
  ISlot,
  ISlotTime,
  IVenue,
  IWidgetView
} from "./interfaces";
import sanitizeHtml from "sanitize-html";
import { store } from "@/store";
import { getTimezone } from "countries-and-timezones";
import spriteSrc from "@/assets/sprite.svg";

export const getUTCDate = (date: string) => {
  const utcMoment = moment.utc(date);
  return utcMoment.format("YYYY-MM-DD");
};

export const getVenueTimeFormatHours = () => {
  return store.state.venue?.timeFormat || 12;
};

export const getHourName = (hour: number, minutes = 0) => {
  hour %= 24;
  let start = hour;
  const hoursFormat = getVenueTimeFormatHours();
  if (hoursFormat === 12) {
    start = hour && (hour % 12 || 12);
  }
  const daytime = hoursFormat === 12 ? (hour < 12 ? "am" : "pm") : "";
  return `${start > 9 || hoursFormat === 12 ? start : `0${start}`}${
    minutes || hoursFormat === 24
      ? `:${minutes > 9 ? minutes : `0${minutes}`}`
      : ""
  }${daytime}`;
};

export const getMinutes = (hour: ISlotTime) => {
  return hour.hours * 60 + hour.minutes;
};

export const getSlotFromMinutes = (minutes: number): ISlotTime => {
  return {
    hours: Math.floor(minutes / 60),
    minutes: minutes % 60
  };
};

export const getTimezoneOffsetHours = (timezone: string) => {
  return (getTimezone(timezone)?.utcOffset || 0) / 60;
};

export const getFormattedSlots = (
  booking: IBooking,
  timezone: string,
  breakTime: number
) => {
  if (!booking) return "";
  const slots: any = [];
  const utcOffset = getTimezoneOffsetHours(timezone);

  booking.slots.forEach((slot: ISlot) => {
    const duration = getMinutes(slot.to) - getMinutes(slot.from);
    const slotBreakTime = slot.breakTime || breakTime;
    const lastSlot = slots[slots.length - 1];
    if (!slots.length) slots.push(slot);
    else if (!Array.isArray(lastSlot))
      if (
        getMinutes(lastSlot) ===
        getMinutes(slot.from) - duration - slotBreakTime
      )
        slots[slots.length - 1] = [lastSlot, slot];
      else slots.push(slot);
    else if (
      getMinutes(lastSlot[1]) ===
      getMinutes(slot.from) - duration - slotBreakTime
    )
      slots[slots.length - 1][1] = slot;
    else slots.push(slot);
  });

  return slots
    .map((slot: ISlot | ISlot[]) => {
      if (!Array.isArray(slot))
        return getHourName(
          getSlotFromMinutes(getMinutes(slot.from) + utcOffset * 60).hours,
          getSlotFromMinutes(getMinutes(slot.from) + utcOffset * 60).minutes
        );
      else
        return `${getHourName(
          getSlotFromMinutes(getMinutes(slot[0].from) + utcOffset * 60).hours,
          getSlotFromMinutes(getMinutes(slot[0].from) + utcOffset * 60).minutes
        )}-${getHourName(
          getSlotFromMinutes(getMinutes(slot[1].from) + utcOffset * 60).hours,
          getSlotFromMinutes(getMinutes(slot[1].from) + utcOffset * 60).minutes
        )}`;
    })
    .join(", ");
};

export const getFormattedBookingTime = (booking: IBooking) => {
  return !booking
    ? ""
    : moment(new Date(booking.date._seconds * 1000).toDateString()).format(
        "ddd MMM D, YYYY"
      );
};

export const getFormattedBookingTimeCheckout = (booking: IBooking) => {
  return !booking
    ? ""
    : moment(new Date(booking.date._seconds * 1000).toDateString()).format(
        "MMMM D, YYYY"
      );
};

export const sanitize = (text: string) => {
  return sanitizeHtml(text, {
    allowedTags: sanitizeHtml.defaults.allowedTags.concat(["img"]),
    allowedAttributes: {
      "*": ["href", "align", "alt", "center", "style", "src", "class"],
      a: ["href", "name", "target"],
      img: ["src", "srcset", "alt", "title", "width", "height", "loading"]
    },
    allowedSchemes: ["data", "http"],
    selfClosing: [
      "img",
      "br",
      "hr",
      "area",
      "base",
      "basefont",
      "input",
      "link",
      "meta"
    ],
    allowedSchemesAppliedToAttributes: ["href", "src", "cite", "data"],
    nestingLimit: 3
  });
};

const US = { code: "USD", symbol: "$" };
const CA = { code: "CAD", symbol: "CA$" };
export const getCurrencyByCountryCode = (countryCode?: string) => {
  return (countryCode && { US, CA }[countryCode]) || US;
};

export const getBookingPrice = (checkoutInfo: ICheckoutInfo) => {
  if (!checkoutInfo || !checkoutInfo.payments.length) return {};

  const {
    total,
    subtotal,
    taxes,
    upsellItems,
    depositPrice,
    upsellTaxes
  } = checkoutInfo;

  const paymentMethodAlias =
    checkoutInfo.payments[checkoutInfo.payments.length - 1]
      ?.paymentMethodAlias || null;

  return {
    total,
    depositPrice,
    subtotal,
    taxes,
    upsellItems,
    upsellTaxes,
    paymentMethodAlias,
    ...checkoutInfo.payments.reduce(
      (previous, current) => {
        const paid = previous.paid + (current.paid || 0);
        const refunded = previous.refunded + (current.refunded || 0);
        const availableForRefund =
          previous.availableForRefund +
          (current.paymentIntentId ? paid - refunded : 0);
        return {
          paid,
          refunded,
          availableForRefund
        };
      },
      {
        paid: 0,
        refunded: 0,
        availableForRefund: 0
      }
    )
  };
};

export const setWidgetViewVars = (view?: IWidgetView) => {
  if (view) {
    if (view.fontFamily) {
      document.documentElement.style.setProperty(
        "--font-family",
        `${view.fontFamily}, sans-serif`
      );
    }
    Object.entries(view.colors).forEach(([varName, color]) => {
      document.documentElement.style.setProperty(
        `--view-color-${varName}`,
        color?.toString()
      );
    });
  }
};
