var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"custom-fields-input mb-24",class:{
      'm-grid': _vm.isGrid,
      'm-margin': _vm.isBadAxeWidget
    }},[_vm._l((_vm.sortedFields.filter(
        field => !_vm.isFieldMultiple(field) && !_vm.isFieldFlag(field)
      )),function(field){return [_c('FormItem',{key:`${field.key}_1`,attrs:{"rules":_vm.getFieldRules(field)},scopedSlots:_vm._u([{key:"default",fn:function({ isError }){return [(_vm.isFieldSelect(field))?_c('Select',{attrs:{"value":_vm.customSelectOptionMap[field.key]
              ? _vm.CUSTOM_SELECT_OPTION
              : field.value,"options":_vm.getFieldOptions(field),"placeholder":field.label + _vm.required(field),"is-error":isError,"is-small":_vm.isBadAxeWidget || _vm.isBadAxe,"is-floating":!(_vm.isBadAxeWidget || _vm.isBadAxe),"variant":_vm.isBadAxeWidget || _vm.isBadAxe ? 'secondary' : 'primary'},on:{"input":function($event){return _vm.handleSelect(field.key, $event)}}}):(!_vm.isFieldDate(field))?_c('Input',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.getFieldMask(field)),expression:"getFieldMask(field)"}],attrs:{"value":field.value,"placeholder":field.label + _vm.required(field),"hint":field.hint,"is-error":isError,"is-floating":!(_vm.isBadAxeWidget || _vm.isBadAxe),"is-small":_vm.isBadAxeWidget || _vm.isBadAxe,"variant":_vm.isBadAxeWidget || _vm.isBadAxe ? 'tertiary' : 'primary'},on:{"input":function($event){return _vm.handleInput(field.key, $event)}}}):_c('Input',{attrs:{"value":field.value,"max":field.isRestrictFutureDates ? _vm.currentDate : '',"isError":isError,"isDate":_vm.isFieldDate(field),"hint":field.hint,"placeholder":field.label + _vm.required(field),"dateFormat":_vm.venue.dateFormat,"isTextInput":_vm.isFieldDate(field),"is-floating":!(_vm.isBadAxeWidget || _vm.isBadAxe),"is-small":_vm.isBadAxeWidget || _vm.isBadAxe,"variant":_vm.isBadAxeWidget || _vm.isBadAxe ? 'tertiary' : 'primary'},on:{"input":function($event){return _vm.handleInput(field.key, $event)}}})]}}],null,true)}),(
          _vm.isFieldSelect(field) &&
            field.isAllowCustomOption &&
            _vm.customSelectOptionMap[field.key]
        )?_c('FormItem',{key:`${field.key}_1`,scopedSlots:_vm._u([{key:"default",fn:function({ isError }){return [_c('Input',{attrs:{"value":field.value,"placeholder":`${field.label + _vm.required(field)} (Your variant)`,"hint":field.hint,"is-error":isError,"is-floating":!(_vm.isBadAxeWidget || _vm.isBadAxe),"is-small":_vm.isBadAxeWidget || _vm.isBadAxe,"variant":_vm.isBadAxeWidget || _vm.isBadAxe ? 'tertiary' : 'primary'},on:{"input":function($event){return _vm.handleInput(field.key, $event)}}})]}}],null,true)}):_vm._e()]})],2),_vm._l((_vm.sortedFields.filter(_vm.isFieldMultiple)),function(field){return _c('FormItem',{key:field.key,staticClass:"mb-24",attrs:{"rules":_vm.getFieldRules(field)}},[_c('CheckboxGroup',{attrs:{"value":field.value,"variant":_vm.isBadAxe ? 'tertiary' : 'secondary',"options":_vm.getFieldOptions(field),"label":field.label + _vm.required(field)},on:{"input":function($event){return _vm.handleInput(field.key, $event)}}})],1)}),_vm._l((_vm.sortedFields.filter(_vm.isFieldFlag)),function(field){return _c('FormItem',{key:field.key,staticClass:"mb-24",attrs:{"rules":_vm.getFieldRules(field)}},[_c('Checkbox',{attrs:{"value":field.value,"variant":_vm.isBadAxe ? 'tertiary' : 'secondary'},on:{"input":function($event){return _vm.handleInput(field.key, $event)}}},[_vm._v(" "+_vm._s(field.label + _vm.required(field))+" ")])],1)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }