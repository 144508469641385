import axios, { AxiosRequestConfig } from "axios";
import { Handlers } from "@/helpers/handlers";
import { IApiParams } from "@/helpers/interfaces";
import Cookies from "js-cookie";
import { store } from "../store";
const Url = process.env.VUE_APP_API_URL || "";

declare global {
  interface Window {
    vue: any;
  }
}

export class ServiceBase {
  protected static async callApi({
    data = null,
    method,
    url,
    baseURL,
    params,
    contentType,
    header,
    signal
  }: IApiParams & { header?: any }) {
    const config: AxiosRequestConfig = {
      baseURL,
      data,
      method,
      url,
      params,
      withCredentials: false,
      signal
    };
    config.url = url;
    if (!config.baseURL) config.baseURL = baseURL || Url;

    if (header) {
      config.headers = header;
    }
    if (contentType) {
      config.headers = {
        ...config.headers,
        "content-type": contentType
      };
    }

    const venueId =
      Cookies.get("yoreferee_venueId") || store.state.venueId || "";
    config.headers = {
      ...config.headers,
      "Yoreferee-Booking-API-VenueId": venueId
    };

    try {
      const response = await axios.request(config);
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        Handlers.defaultErrorAPIHandler(
          error.response?.data.error != null
            ? error.response.data.error
            : error.response?.data,
          error.response?.status
        );
      } else if (axios.isAxiosError(error) && error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        Handlers.defaultErrorHandler(
          "Произошла ошибка при отправлении запроса, либо сервер не ответил вовремя"
        );
      } else {
        // handlers.defaultErrorHandler(error.message);
        // Something happened in setting up the request that triggered an Error
      }
      throw error;
    }
  }
}
