import { GlobalService } from "@/api/services/GlobalService";
import {
  ICheckoutRequest,
  ISignature,
  IUploadMedia,
  ISlot,
  IWaiver,
  IProduct
} from "@/helpers/interfaces";
import { getMinutes } from "../helpers/utils";
import axios from "axios";

export const actions = {
  async getProducts({ commit }: any) {
    let result = false;
    commit("SET_IS_PRODUCTS_LOADING", true);
    const products = await GlobalService.getProducts();
    result = true;
    commit("SET_IS_PRODUCTS_LOADING", false);
    commit("SET_PRODUCTS", products);
    return result;
  },
  async getVenue({ commit }: any) {
    let result = false;
    commit("SET_IS_PRODUCTS_LOADING", true);
    const venue = await GlobalService.getVenue();
    result = true;
    commit("SET_IS_PRODUCTS_LOADING", false);
    commit("SET_VENUE", venue);
    return result;
  },
  async getWidgetTemplate({ commit, state: { widgetTemplateId } }: any) {
    let result = false;
    commit("SET_IS_PRODUCTS_LOADING", true);
    const venue = await GlobalService.getWidgetTemplate(widgetTemplateId);
    result = true;
    commit("SET_IS_PRODUCTS_LOADING", false);
    commit("SET_WIDGET_TEMPLATE", venue);
    return result;
  },
  async getWaiverInfo({ state: { productId }, commit }: any) {
    let result = false;

    const waiver: IWaiver = await GlobalService.getWaiverInfo(productId);

    result = true;
    commit("SET_WAIVER_INFO", waiver);

    return result;
  },
  async getProductInfo({ state: { productId }, commit }: any) {
    let result = false;
    const product: IProduct = await GlobalService.getProductInfo(productId);
    result = true;
    commit("SET_PRODUCT_INFO", product);
    return result;
  },

  async getBooking({ commit }: any, { bookingId }: { bookingId: string }) {
    let result = false;
    const booking = await GlobalService.getBooking({ bookingId });
    result = true;
    commit("SET_BOOKING", booking);

    return result;
  },

  async getFreeSlots(
    { state: { productId }, commit }: any,
    { date, playersCount }: { date: string; playersCount: number }
  ) {
    let result = false;
    let isCanceled = false;
    commit("SET_FREE_SLOTS_LOADING", true);
    try {
      const response = await GlobalService.getFreeSlots({
        productId,
        date,
        playersCount
      });
      if (response) {
        const { freeSlots, allSlots } = response;
        result = true;
        const isSlotsEqual = (slot: ISlot, otherSlot: ISlot) => {
          return (
            getMinutes(slot.from) === getMinutes(otherSlot.from) &&
            getMinutes(slot.to) === getMinutes(otherSlot.to) &&
            slot.dateIso === otherSlot.dateIso &&
            slot.price === otherSlot.price &&
            slot.fixedPrice === otherSlot.fixedPrice
          );
        };
        const getUniqueSlots = (prev: ISlot[], slot: ISlot) => {
          if (
            slot.isPrivate ||
            !prev.some(
              otherSlot => !otherSlot.isPrivate && isSlotsEqual(slot, otherSlot)
            )
          ) {
            return [...prev, slot];
          }
          return prev;
        };
        commit("SET_FREE_SLOTS", freeSlots.reduce(getUniqueSlots, []));
        commit("SET_ALL_SLOTS", allSlots.reduce(getUniqueSlots, []));
      } else {
        commit("SET_FREE_SLOTS", []);
        commit("SET_ALL_SLOTS", []);
      }
    } catch (e) {
      isCanceled = axios.isCancel(e);
    } finally {
      if (!isCanceled) {
        commit("SET_FREE_SLOTS_LOADING", false);
      }
    }
    return result;
  },

  async createBooking(
    {
      state: { productId, chosenSlots, chosenDate, selectedGroup },
      commit
    }: any,
    { playersCount }: { playersCount: number }
  ) {
    let result = false;
    const params = {
      productId,
      playersCount,
      slots: chosenSlots,
      date: chosenDate,
      ...(selectedGroup
        ? {
            selectedGroupId: selectedGroup.id,
            selectedGroup: selectedGroup.value
          }
        : {})
    };

    const booking = await GlobalService.createBooking(params);
    result = true;
    commit("SET_BOOKING", booking);
    commit("SET_BOOKING_ID", booking?.id);
    return result;
  },

  async updateBooking(
    {
      state: {
        productId,
        booking: { id: bookingId },
        chosenSlots,
        chosenDate
      },
      commit
    }: any,
    { playersCount }: { playersCount: number }
  ) {
    let result = false;
    const booking = await GlobalService.updateBooking({
      productId,
      bookingId,
      playersCount,
      slots: chosenSlots,
      date: chosenDate
    });
    result = true;
    commit("SET_BOOKING", booking);
    return result;
  },
  async removeBooking(_: any, bookingId: string) {
    let result = false;
    await GlobalService.deleteBooking(bookingId);
    result = true;
    return result;
  },
  async addReservationInfo({
    state: {
      booking: { id: bookingId },
      reservationInfo
    },
    commit
  }: any) {
    let result = false;
    const booking = await GlobalService.addReservationInfo({
      bookingId,
      data: {
        ...reservationInfo,
        phone: reservationInfo.phone.replace(/[^\d+]/g, "")
      }
    });
    result = true;
    commit("SET_BOOKING", booking);
    return result;
  },

  async checkout(
    {
      state: {
        productId,
        booking: { id: bookingId },
        selectedGroup
      }
    }: any,
    data: ICheckoutRequest
  ) {
    const checkout = await GlobalService.checkout({
      productId,
      bookingId,
      data: {
        ...data,
        ...(selectedGroup ? { selectedGroupId: selectedGroup.id } : {})
      }
    });

    return checkout.url;
  },

  async createSignature(
    {
      state: {
        productId,
        booking: { id: bookingId }
      },
      commit
    }: any,
    data: ISignature
  ) {
    const fullName = `${data.fields.firstName?.value || ""} ${data.fields
      .lastName?.value || ""}`;
    const signature = await GlobalService.createSignature({
      productId,
      data: {
        ...data,
        fullName,
        bookingId
      }
    });
    commit("SET_SIGNATURE", signature);
  },

  async getPrice(
    { state: { productId } }: any,
    {
      playersCount,
      slots,
      upsellItems,
      isDeposit,
      selectedGroupId,
      promocode
    }: {
      isDeposit: boolean;
      playersCount: number;
      slots: ISlot[];
      upsellItems: string[];
      selectedGroupId?: string;
      promocode?: string;
    }
  ) {
    return await GlobalService.getPrice({
      productId,
      isDeposit,
      playersCount,
      slots,
      upsellItems,
      ...(selectedGroupId ? { selectedGroupId } : {}),
      promocode
    });
  },

  async getDates(
    { state: { productId, bookingId } }: any,
    {
      playersCount,
      month
    }: {
      playersCount: number;
      month: string;
    }
  ) {
    try {
      return await GlobalService.getDates({
        productId,
        bookingId,
        playersCount,
        month
      });
    } catch (e) {
      console.log(e);
      return false;
    }
  },

  async uploadMedia(_: any, data: IUploadMedia) {
    try {
      return await GlobalService.uploadMedia(data);
    } catch (e) {
      console.log(e);
      return false;
    }
  },

  setLoadingCheckout({ commit }: any, isLoading: boolean) {
    commit("SET_LOADING_CHECKOUT", isLoading);
  }
};
