<template>
  <div class="payment-expired">
    <div class="payment-expired__container">
      <h2 class="payment-expired__title" :class="[{ 'm-white': isWhite }]">
        Your booking has expried
      </h2>
    </div>
    <div class="payment-expired__bottom">
      <Button
        class="payment-expired__next-button-white"
        :class="[{ 'm-white': isWhite }]"
        variant="white"
        @click="handleResetBooking"
        isOutlined
      >
        Start over
      </Button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { GlobalService } from "@/api/services/GlobalService";
import { BookingInteractionEnum } from "@/api/types";

export default {
  name: "PaymentExpired",
  props: {
    isWhite: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({}),
  computed: {
    ...mapState({
      productId: (state) => state.productId,
      interactionClientId: (state) => state.interactionClientId,
    }),
  },
  methods: {
    ...mapActions(["getProductInfo", "getProducts"]),
    ...mapMutations({
      setProductId: "SET_PRODUCT_ID",
      setReservationInfo: "SET_RESERVATION_INFO",
      setPlayersCount: "SET_PLAYERS_COUNT",
      setChosenDate: "SET_CHOSEN_DATE",
      removeFromDatesAvailabilities: "REMOVE_FROM_DATES_AVAILABILITIES",
    }),

    async handleResetBooking() {
      this.setReservationInfo({
        firstName: "",
        lastName: "",
        phone: "",
        email: "",
        customFields: {},
      });

      this.setProductId(this.productId);

      try {
        this.setPlayersCount(null);
        this.setChosenDate("");
        this.removeFromDatesAvailabilities();
        await this.getProductInfo();
        GlobalService.sendInteraction({
          interaction: BookingInteractionEnum.SELECT_PRODUCT,
          clientId: this.interactionClientId,
          productId: this.productId,
        });
      } catch (e) {
        console.log(e);
      }

      this.$emit("reset");
    },
  },
};
</script>

<style lang="scss" scoped>
.payment-expired {
  display: flex;
  height: 100%;
  width: 100%;
  padding: 64px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: var(--font-family);

  &__title {
    width: 100%;
    font-size: 24px;
    margin-top: 5px;
    line-height: 30px;
    color: var(--color-black-100);
    font-weight: 500;
    margin-bottom: 32px;

    @media (min-width: 768px) {
      margin-bottom: 24px;
    }

    &.m-white {
      color: var(--color-white);
    }
  }

  &__bottom {
    button {
      height: 52px;
      font-size: 16px;
      padding: 14px 48px;
      min-width: unset;

      width: 100%;

      @media (min-width: 768px) {
        width: unset;
      }
    }
  }
  &__next-button-white {
    color: var(--color-black-100);

    &.m-white {
      color: var(--color-white);
    }
  }
}
</style>
