import Vue from "vue";
import App from "./App.vue";
import { store } from "@/store";
import VueRouter from "vue-router";
import Cookies from "js-cookie";
import { v4 as uuid, validate as uuidValidate } from "uuid";
import { WidgetPositionEnum, WidgetThemeEnum } from "@/helpers/interfaces";
import { install, Packages, SvgIconsCollection } from "@zteam/booking-axe-sb";
import "@/assets/styles/styles.scss";
import VScrollLock from "v-scroll-lock";
import device from "vue-device-detector";
import browserDetect from "vue-browser-detect-plugin";
import VueI18n from "vue-i18n";
import en from "@/assets/locales/en.json";
import { setWidgetViewVars } from "@/helpers/utils";
import IdleVue from "idle-vue";
import iframeResize from "iframe-resizer/js/iframeResizer";
import "iframe-resizer/js/iframeResizer.contentWindow.min";
import { svgIconsCollection } from "@/helpers/svgIcons";

const IDLE_SECONDS = 300;
Vue.use(IdleVue, {
  eventEmitter: new Vue(),
  idleTime: 1000 * IDLE_SECONDS,
});

Vue.use(VueI18n);
Vue.use(browserDetect);
Vue.use(device);
Vue.use(VScrollLock, {
  bodyScrollOptions: {
    reserveScrollBarGap: true,
  },
});
Vue.use(install);
Vue.use(Packages);
Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
});

const i18n = new VueI18n({
  locale: "en-US", // set locale
  messages: {
    en,
  },
});

Vue.directive("resize", {
  bind: function(el, { value }) {
    if (value?.active) {
      el.addEventListener("load", () => iframeResize(value.params || {}, el));
    }
  },
  unbind: function(el) {
    el.iFrameResizer.removeListeners();
  },
});

const logProgress = (msg) => {
  console.log(
    `%c[YoReferee Booking Widget] %c${msg}`,
    "color: #286eed; font-size: larger;",
    "color: initial; font-size: normal;",
  );
};

logProgress("Initializing...");
if (document) {
  document.addEventListener("DOMContentLoaded", () => {
    const body = document.querySelector("body");
    const head = document.querySelector("head");

    if (body) {
      const routerParams = new Proxy(
        new URLSearchParams(window.location.search),
        {
          get: (searchParams, prop) => searchParams.get(prop),
        },
      );
      const script = document.querySelector("script[data-venue-id]");
      const scriptVenueId = script?.getAttribute("data-venue-id");
      const queryVenueId = routerParams.venueId;

      if (scriptVenueId || queryVenueId) {
        const widgetTemplateId =
          routerParams.widgetTemplateId ||
          script?.getAttribute("data-widget-template-id") ||
          "";
        let productId =
          routerParams.productId ||
          script?.getAttribute("data-product-id") ||
          "";
        const venueId = queryVenueId || scriptVenueId;
        let position =
          routerParams.position || script?.getAttribute("data-position");
        let isPromocodesAvailable = !!+(
          routerParams.promocodes || script?.getAttribute("data-promocodes")
        );
        let isHideBeacon = !!+(
          routerParams.hideBeacon || script?.getAttribute("data-hide-beacon")
        );
        if (!Object.values(WidgetPositionEnum).includes(position)) {
          position = WidgetPositionEnum.FLOATING;
        }
        const rawView =
          (routerParams.view && decodeURIComponent(routerParams.view)) ||
          script?.getAttribute("data-view") ||
          "";
        let view = rawView && JSON.parse(rawView);

        let interactionClientId = Cookies.get(
          "yoreferee_bookingInteractionClientId",
        );
        if (!interactionClientId || !uuidValidate(interactionClientId)) {
          interactionClientId = uuid();
          Cookies.set(
            "yoreferee_bookingInteractionClientId",
            interactionClientId,
            {
              expires: 3,
            },
          );
        }

        Cookies.set("yoreferee_venueId", venueId);
        store.commit("SET_VENUE_ID", venueId);
        store.commit("SET_INTERACTION_CLIENT_ID", interactionClientId);

        const isAlwaysOpen = !!+routerParams.isAlwaysOpen;
        store.commit("SET_IS_ALWAYS_OPEN", isAlwaysOpen);

        queueMicrotask(async () => {
          await store.dispatch("getVenue");
          const templateId =
            widgetTemplateId || store.state.venue.defaultWidgetTemplateId;
          store.commit("SET_WIDGET_TEMPLATE_ID", templateId);
          if (templateId) {
            await store.dispatch("getWidgetTemplate");
            const widgetTemplate = store.state.widgetTemplate;
            if (widgetTemplate) {
              if (!widgetTemplate?.isActive) {
                logProgress("Widget is not active.");
                return;
              }

              productId = widgetTemplate.productId;
              position = widgetTemplate.position;
              isHideBeacon = widgetTemplate.isHideBeacon;
              isPromocodesAvailable = widgetTemplate.isPromocodesAvailable;
              view = widgetTemplate.view;
            }
          }

          store.commit("SET_PRODUCT_ID", productId);
          store.commit("SET_POSITION", position);
          store.commit("SET_IS_HIDE_BEACON", isHideBeacon);
          store.commit("SET_IS_PROMOCODES_AVAILABLE", isPromocodesAvailable);
          store.commit("SET_WIDGET_VIEW", view);
          store.commit("SET_IS_BASE_PRODUCT_ID", !!productId);

          setWidgetViewVars(view);

          if (productId) {
            await store.dispatch("getProductInfo");
          }
          Cookies.set(
            "yoreferee_widget",
            store.state.venue.widget || WidgetThemeEnum.YOREFEREE,
          );

          let app;
          if (position === WidgetPositionEnum.EMBEDDED) {
            app = document.querySelector("#reserve-lane");
          } else {
            app = document.createElement("div");
            app.setAttribute("id", "reserve-lane");
            body.appendChild(app);
          }

          if (head) {
            const link = document.createElement("link");
            link.setAttribute("href", `${process.env.BASE_URL}css/app.css`);
            link.setAttribute("rel", "stylesheet");
            head.appendChild(link);
          }

          if (app) {
            const mountPoint = app.appendChild(document.createElement("div"));
            window.vue = new Vue({
              el: mountPoint,
              render: (h) => h(App),
              store,
              i18n,
              router,
              provide: {
                [SvgIconsCollection]: svgIconsCollection,
              },
            });
            logProgress("Initialized.");
          } else {
            logProgress("Failed to initialize widget. Mount point is missing.");
          }
        });
      } else {
        logProgress(
          "Failed to initialize widget. <script data-venue-id> is missing.",
        );
      }
    } else {
      logProgress("Failed to initialize widget. <body> is missing.");
    }
  });
} else {
  logProgress("Failed to initialize widget. <document> is missing.");
}
