export interface IApiParams {
  data?: any;
  method: Method;
  section?: "private" | "public";
  url: string;
  token?: string;
  contentType?: string;
  baseURL?: string;
  params?: object;
  signal?: AbortSignal;
}

export interface IGroup {
  id: string | number;
  label: string;
  value: {
    fixedPrice: number;
    maximum: number;
    minumum: number;
  };
}

export interface ISelectedGroup {
  uuid: string | number;
  minimum: number;
  maximum: number;
  fixedPrice: number;
}

export enum CustomFieldTypeEnum {
  TEXT = "text",
  EMAIL = "email",
  DATE = "date",
  PHONE = "phone",
  SELECT = "select",
  NUMERIC = "numeric",
  MULTIPLE = "multiple",
  FLAG = "flag",
}

export type Method =
  | "get"
  | "GET"
  | "delete"
  | "DELETE"
  | "head"
  | "HEAD"
  | "options"
  | "OPTIONS"
  | "post"
  | "POST"
  | "put"
  | "PUT"
  | "patch"
  | "PATCH"
  | "link"
  | "LINK"
  | "unlink"
  | "UNLINK";

export interface IProduct extends IProductReservationInfo {
  name: string;
  id: string;
  cutoffTime: number;
  duration: string;
  title: string;
  breakTime: string;
  depositType: string;
  description?: string;
  logo?: string;
  cutOff: PRODUCT_CUT_OFF;
  price: number;
  customFields?: Record<string, ICustomField>;
}

export interface IWorkingHour {
  [date: string]: {
    from: number;
    to: number;
  };
}

export interface IProductReservationInfo {
  upsell?: {
    text: string;
    price: number;
  };
  maxPlayers: number;
  minPlayers: number;
  inputFields?: string[];
}

export interface IReservationInfo {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  comment: string;
  customFields: Record<string, ICustomField>;
}

export interface ICheckoutSession {
  url: string;
}

enum PRODUCT_CUT_OFF {
  "1 hours before" = 1,
  "2 hours before" = 2,
}

export type Timestamp = { _seconds: number };

export enum BookingStatusEnum {
  NEW = "new",
  WAIT_FOR_PAYMENT = "wait_for_payment",
  PAID = "paid",
  CANCELED = "canceled",
}

export interface IBookingPriceItem {
  name: string;
  amount: number;
}

export interface IBookingPrice {
  subtotal: number;
  taxes: IBookingPriceItem[];
  discounts: IBookingPriceItem[];
  total: number;
}

export interface ICheckoutInfo extends IBookingPrice {
  sessionId: string;
  paid?: number;
  refunded?: number;
  depositPrice: number;
  isCustom: boolean;
  payments: {
    paid: number;
    refunded: number;
    paymentIntentClientSecret: string;
    paymentIntentId: string;
    paymentMethodAlias?: string;
  }[];
  paymentMethodAlias?: string;
  upsellItems: { id: string; quantity: number }[];
  upsellTaxes: { name: string; amount: number }[];
}

export type Modify<T, R> = Omit<T, keyof R> & R;

export interface IBooking {
  id: string;
  date: Timestamp;
  dateIso: string;
  slots: ISlot[];
  registrationCode: number;
  reservationInfo?: IReservationInfo;
  status: BookingStatusEnum;
  checkoutInfo?: ICheckoutInfo;
  createdAt: Timestamp;
  activeWaiver?: Modify<IWaiver, { fields: Partial<IWaiverFields> }> | null;
  delayedTaskName?: string | null;
  expiresAt?: Timestamp | null;
  abandonedAt?: Timestamp | null;
  isSaved: boolean;
  isRefunded?: boolean;
}

export interface ISlotTime {
  hours: number;
  minutes: number;
}

export type ISlot = {
  date: Timestamp;
  dateIso: string;
  from: ISlotTime;
  to: ISlotTime;
  id: string;
  breakTime: number;
  price: number;
  fixedPrice?: number;
  isPrivate: boolean;
  isVisibleOnline: boolean;
};

export interface IDate {
  available: number;
  capacity: number;
  fromPrice: number;
  isAvailable: boolean;
  isWorking: boolean;
}

export interface IDates {
  [key: string]: IDate;
}

export interface IVenue {
  name: string;
  companySite: string;
  companyName: string;
  country: string;
  timezone: string;
  address: string;
  stripeAccount?: string;
  status: VenueStatusEnum;
  timeFormat?: 12 | 24;
  isShowCountDownTimer?: boolean;
  bookingLifeTime?: number;
}

export enum VenueStatusEnum {
  PENDING = "pending",
  RESOLVED = "resolved",
  REJECTED = "rejected",
}

export interface ISignature {
  fields: Partial<IWaiverFields>;
  signature: string;
  fullName: string;
  createdAt?: Timestamp;
  bookingId?: string;
  isGroupEvent?: boolean;
  reference?: string;
}

export interface ICustomField {
  type: CustomFieldTypeEnum;
  label: string;
  hint: string;
  isRequired: boolean;
  value?: string | string[];
  sortWeight: number;
  isDisabled: boolean;
  options?: string[];
}

export interface IWaiverFields {
  firstName: ICustomField & { type: CustomFieldTypeEnum.TEXT };
  lastName: ICustomField & { type: CustomFieldTypeEnum.TEXT };
  email: ICustomField & { type: CustomFieldTypeEnum.EMAIL };
  address: ICustomField & { type: CustomFieldTypeEnum.TEXT };
  birthdate: ICustomField & { type: CustomFieldTypeEnum.DATE };
  phone: ICustomField & { type: CustomFieldTypeEnum.PHONE };

  [k: string]: ICustomField;
}

export interface IWaiver {
  title: string;
  intro: string;
  text: string;
  fields: IWaiverFields;
  checkboxes: string[];
}

export interface ICheckoutRequest {
  isDeposit: boolean;
  timezone: string;
  upsellItems: { id: string; quantity: number }[];
  selectedGroupId?: string;
}

export interface IUploadMedia {
  dataUrl: string;
  scope: string;
}

export enum WidgetThemeEnum {
  BADAXE = "badaxe",
  YOREFEREE = "yoreferee",
  YOREFEREE2 = "yoreferee2",
}

export enum WidgetPositionEnum {
  EMBEDDED = "embedded",
  FLOATING = "floating",
}

export interface IWidgetView {
  fontFamily?: string;
  colors: Record<string, string>;
  isUseAvailabilityColors: boolean;
}

export interface IWidgetTemplate {
  name: string;
  productId?: string;
  position: WidgetPositionEnum;
  isHideBeacon: boolean;
  isPromocodesAvailable: boolean;
  isShowCountDownTimer: boolean;
  isActive: boolean;
  view: IWidgetView;
}
