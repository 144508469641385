<template>
  <div class="widget-block">
    <div class="badaxe">
      <div
        class="badaxe__content"
        :class="[
          {
            'm-iphone': $device.iphone,
            'm-iphoneX':
              $device.iphoneX || $device.iPhoneXR || $device.iPhoneXSMax,
            'm-safari': isSafari && $browserDetect.meta.version === '15',
          },
        ]"
      >
        <div class="badaxe__header">
          <span class="badaxe__title" v-if="step === 1">Choose a product</span>
          <p class="badaxe__title" v-if="step > 1">
            SELECT A <span class="m-bold m-red">DATE & TIME</span> FOR YOUR
            BOOKING
          </p>
        </div>

        <div v-if="step > 1">
          <steps :list="steps" :active="currentStepsNum" />
        </div>
        <div class="badaxe__wrapper">
          <booking-product-modal
            v-if="step === 1"
            @product-selected="step = 2"
            @back="goBack"
          />
          <datepicker v-if="step === 2" @submit="step = 3" />
          <time-slots v-if="step === 3" @submit="step = 4" @back="goBack" />
          <checkout
            v-if="step === 4"
            @calendar="step = 2"
            @submit="handleCheckoutCompleted"
            @back="goBack"
            @reset="step = 2"
            :is-group="true"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapMutations, mapState, mapActions } from "vuex";
import TimeSlots from "@/components/badaxe/TimeSlots";
import Datepicker from "@/components/badaxe/Datepicker";
import Checkout from "@/components/badaxe/Checkout";
import BookingProductModal from "@/components/widget-old/steps/1/BookingProductModal";
import Cookies from "js-cookie";
import { WidgetThemeEnum } from "@/helpers/interfaces";
import Steps from "@/components/badaxe/Steps";
import { GlobalService } from "@/api/services/GlobalService";
import { BookingInteractionEnum } from "@/api/types";

export default {
  name: "Badaxe",
  components: {
    Steps,
    BookingProductModal,
    TimeSlots,
    Datepicker,
    Checkout,
  },
  data() {
    return {
      step: 1,
      isSafari: window?.navigator?.userAgent?.toLowerCase()?.includes("safari"),
    };
  },
  async created() {
    const widgetTheme = Cookies.get("yoreferee_widget");
    this.SET_WIDGET_THEME(widgetTheme || WidgetThemeEnum.BADAXE);
    if (this.productId && this.isBaseProductId) {
      this.step = 2;
    }

    if (this.bookingId) {
      await this.getBooking({
        bookingId: this.bookingId,
      });
      if (
        this.booking?.status === "new" ||
        this.booking?.status === "waiting_for_payment"
      ) {
        await this.removeBooking(this.bookingId);
        this.SET_BOOKING_ID("");
        this.SET_BOOKING("");
      }
    }
  },
  computed: {
    ...mapState({
      venue: (state) => state.venue,
      product: (state) => state.product,
      bookingId: (state) => state.bookingId,
      booking: (state) => state.booking,
      productId: (state) => state.productId,
      isBaseProductId: (state) => state.isBaseProductId,
      interactionClientId: (state) => state.interactionClientId,
    }),
    currentStepsNum() {
      if (this.step === 2) {
        return 0;
      } else if (this.step === 3) {
        return 1;
      } else if (this.step > 3) {
        return 2;
      }
      return 0;
    },
    steps() {
      return [
        {
          id: 0,
          title: "DATE",
        },
        {
          id: 1,
          title: "DETAILS",
        },
        {
          id: 2,
          title: "PAYMENT",
        },
      ];
    },
  },
  methods: {
    ...mapActions(["removeBooking", "getBooking"]),
    ...mapMutations({
      SET_WIDGET_THEME: "SET_WIDGET_THEME",
      SET_BOOKING: "SET_BOOKING",
      SET_BOOKING_ID: "SET_BOOKING_ID",
    }),
    handleClose() {
      window.parent.postMessage("modal-hide", "*");
    },
    async goBack() {
      if (this.step !== 1) {
        if (
          (this.step === 4 && this.booking.status.toLowerCase() === "new") ||
          this.booking.status === "waiting_for_payment"
        ) {
          await this.removeBooking(this.booking.id);
        }
        this.step -= 1;
      }
    },
    handleCheckoutCompleted() {
      this.$modal.show("success-modal");

      GlobalService.sendInteraction({
        interaction: BookingInteractionEnum.PAYMENT_SUCCESS,
        clientId: this.interactionClientId,
        productId: this.productId,
      });
    },
  },
  watch: {
    productId() {
      if (this.isBaseProductId && this.productId) this.step = 2;
    },
    isBaseProductId() {
      if (this.productId && this.isBaseProductId) this.step = 2;
    },
  },
};
</script>

<style>
:root {
  --font-family: var(--font-family);
}
</style>

<style lang="scss" scoped>
.vm--container {
  .vm--modal.m-book-modal {
    box-shadow: none;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100% !important;
  }

  .vm--modal.m-book-modal-new {
    justify-content: flex-end;
    border-radius: 0 !important;

    @media (min-width: 768px) {
      justify-content: center;
    }
  }
}

.widget-block {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 60px;
  padding-bottom: 60px;
  font-family: var(--font-family);
}

.badaxe {
  width: 100%;
  //box-shadow: var(--box-shadow-large);
  //border: 2px solid var(--color-secondary-400);
  padding: 16px 12px 24px;

  @media (min-width: 768px) {
    padding: 24px 20px 24px;
  }

  @media (min-width: 1024px) {
    width: 100%;
  }

  &__content {
    height: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 20px 20px 0 0;
    background-color: var(--color-white);

    @media (min-width: 768px) {
      border-radius: 0;
    }

    &.m-iphone {
      padding-bottom: 50px;

      &.m-iphoneX {
        padding-bottom: 100px;
      }

      &.m-safari {
        padding-bottom: 80px;
      }
    }
  }

  &__back {
    width: 24px;
    margin-right: 16px;
    display: flex;
  }

  &__wrapper {
    flex: 1;
    height: auto;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 16px;
  }

  &__title {
    font-family: inherit;
    font-weight: 400;
    font-size: 24px;
    color: var(--color-black);
    text-align: center;
    text-transform: uppercase;

    .m-red {
      color: var(--color-red-10);
    }

    .m-bold {
      font-weight: 600;
    }
  }

  &__icon {
    width: 24px;
    min-width: 24px;
    margin-left: 16px;
  }
}
</style>
