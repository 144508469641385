<template>
  <div class="upsell-item-card">
    <div class="upsell-item-card__content">
      <div v-if="image" class="upsell-item-card__image">
        <img :src="image" alt="" />
      </div>
      <div
        :class="[
          'upsell-item-card__text',
          { 'm-black': isBadAxeWidget, 'm-font': isBadAxeWidget }
        ]"
      >
        <div class="upsell-item-card__top">{{ title }}</div>
        <p class="upsell-item-card__description">
          {{ description || "&nbsp;" }}
        </p>
      </div>
    </div>
    <div
      v-if="innerValue > 0"
      :class="['upsell-item-card__counter', { 'm-black': isBadAxeWidget }]"
    >
      <button
        type="button"
        :class="[
          'upsell-item-card__counter__button',
          { 'm-red': isBadAxeWidget, 'm-font': isBadAxeWidget }
        ]"
        @click="innerValue = Math.max(0, innerValue - 1)"
      >
        <Icon name="minus" :size="18" color="white" />
      </button>
      {{ innerValue }}
      <button
        type="button"
        :class="[
          'upsell-item-card__counter__button',
          { 'm-red': isBadAxeWidget, 'm-font': isBadAxeWidget }
        ]"
        @click="innerValue += 1"
      >
        <Icon name="plus" :size="18" color="white" />
      </button>
    </div>
    <button
      v-else
      :class="[
        'upsell-item-card__button',
        { 'upsell-item-card__button-new': isBadAxeWidget }
      ]"
      @click="innerValue = 1"
    >
      ${{ price }}
    </button>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "UpsellItemCard",
  props: {
    value: {
      // quantity
      type: Number,
      default: 0
    },
    image: {
      type: String
    },
    title: {
      type: String,
      required: true
    },
    description: {
      type: String
    },
    price: {
      type: [String, Number],
      default: 0
    }
  },
  computed: {
    ...mapGetters({
      isBadAxeWidget: "isBadAxeWidget"
    }),
    innerValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.upsell-item-card {
  padding: 16px;
  background-color: var(--view-color-primary);
  filter: brightness(110%);
  box-shadow: var(--box-shadow-small);
  border-radius: 8px;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 14px;

  &__image {
    background-color: var(--color-white);
    border-radius: 8px;
    height: 75px;
    width: 75px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-basis: 75px;
    flex-shrink: 0;

    @media (min-width: 768px) {
      height: 100px;
      width: 100px;
      flex-basis: 100px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__content {
    display: flex;
    gap: 14px;
    flex: 1;
  }

  &__text {
    display: flex;
    flex-direction: column;
    gap: 12px;
    color: var(--color-white);

    &.m-black {
      color: var(--color-black);
    }

    &.m-font {
      font-family: var(--font-family);
    }
  }

  &__title {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: inherit;
  }

  &__description {
    font-size: 16px;
    line-height: 24px;
    color: inherit;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__button {
    background: var(--view-color-primary);
    color: var(--color-white);
    filter: brightness(90%);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px 24px;
    width: 100%;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.02em;
    border-radius: 8px;
    border: none;
    cursor: pointer;

    &-new {
      background: var(--color-red-10);
    }
  }

  &__counter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    font-size: 16px;
    line-height: 24px;
    color: var(--color-white);

    &.m-black {
      color: var(--color-black);
    }

    &__button {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--view-color-primary);
      filter: brightness(90%);
      border-radius: 6px;
      border: none;
      padding: 12px 16px;
      cursor: pointer;

      &.m-red {
        background-color: var(--color-red-10);
      }

      &.m-font {
        font-family: var(--font-family);
      }
    }
  }
}
</style>
