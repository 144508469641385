var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bookingProductModal",class:{ 'm-small': _vm.isBadAxeWidget }},[(_vm.isProductsLoading || _vm.hasDefaultProduct)?_c('div',{staticClass:"bookingProductModal__loader"},[_c('Loader',{attrs:{"color":_vm.isBadAxeWidget ? 'red' : 'white',"size":"l"}})],1):_c('div',{class:[
      'bookingProductModal__list',
      {
        'm-grid': _vm.isBadAxeWidget,
        'm-fullsize':
          _vm.isBadAxeWidget &&
          _vm.filteredProducts.length % 1 === 0 &&
          _vm.filteredProducts.length % 2 !== 0,
      },
    ]},_vm._l((_vm.filteredProducts),function(product,index){return _c('product-card',{key:index,staticClass:"bookingProductModal__item",class:{
        'm-badaxe': _vm.isBadAxeWidget,
      },attrs:{"name":product.name,"title":product.title,"description":product.description,"image":product.image,"is-deposit":!!product.deposit && product.deposit !== '' && product.deposit !== '0'},nativeOn:{"click":function($event){return (() => _vm.handleProductClick(product)).apply(null, arguments)}}})}),1)])
}
var staticRenderFns = []

export { render, staticRenderFns }