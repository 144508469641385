var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"widget-block"},[_c('div',{staticClass:"badaxe"},[_c('div',{staticClass:"badaxe__content",class:[
        {
          'm-iphone': _vm.$device.iphone,
          'm-iphoneX':
            _vm.$device.iphoneX || _vm.$device.iPhoneXR || _vm.$device.iPhoneXSMax,
          'm-safari': _vm.isSafari && _vm.$browserDetect.meta.version === '15',
        },
      ]},[_c('div',{staticClass:"badaxe__header"},[(_vm.step === 1)?_c('span',{staticClass:"badaxe__title"},[_vm._v("Choose a product")]):_vm._e(),(_vm.step > 1)?_c('p',{staticClass:"badaxe__title"},[_vm._v(" SELECT A "),_c('span',{staticClass:"m-bold m-red"},[_vm._v("DATE & TIME")]),_vm._v(" FOR YOUR BOOKING ")]):_vm._e()]),(_vm.step > 1)?_c('div',[_c('steps',{attrs:{"list":_vm.steps,"active":_vm.currentStepsNum}})],1):_vm._e(),_c('div',{staticClass:"badaxe__wrapper"},[(_vm.step === 1)?_c('booking-product-modal',{on:{"product-selected":function($event){_vm.step = 2},"back":_vm.goBack}}):_vm._e(),(_vm.step === 2)?_c('datepicker',{on:{"submit":function($event){_vm.step = 3}}}):_vm._e(),(_vm.step === 3)?_c('time-slots',{on:{"submit":function($event){_vm.step = 4},"back":_vm.goBack}}):_vm._e(),(_vm.step === 4)?_c('checkout',{attrs:{"is-group":true},on:{"calendar":function($event){_vm.step = 2},"submit":_vm.handleCheckoutCompleted,"back":_vm.goBack,"reset":function($event){_vm.step = 2}}}):_vm._e()],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }